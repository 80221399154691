<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid">

         <!-- Link With HPR Start -->
         

         <div [formGroup]="hprForm1" *ngIf="EnableLinkHPR">
         <div class="row mt-4 mb-4">
                <div>
                <input type="checkbox" [(ngModel)]="isHPR" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="col-xl-3" >  
                <h4>Link HPR</h4> </div>
        </div>
      <div *ngIf="isHPR">
        <div class="row mb-4">
            <div class="col-xl-4 mb-2">
                <label class="control-label">Aadhaar Number </label>
                <input type="text" autocomplete="off" id="craadhar" placeholder="Enter Aadhaar number" maxlength="12" formControlName="aadhar"
                    onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                    class="form-control form-control-sm">

                    <input type="checkbox" class="mb-2" name="checkbox" [(ngModel)]="istermsChecked"
                    [ngModelOptions]="{standalone: true}" (change)="termscheck($event)">
                   &nbsp;&nbsp; <label class="form-check-label" for="inlineCheckbox22" style="color: #0061f2;cursor: pointer;" data-toggle="modal"
                   data-target="#termsModal"> Terms and Conditions </label>
            
       
        <div>
            {{display}}
            <button class="btn btn-sm btn-success" (click)="GetOtp()" id="Btn">Get Aadhaar OTP</button>

            <!-- <button class="btn btn-sm btn-success" (click)="GetOtp()" id="Btn" *ngIf="!resend_otp" [disabled]="!enableBtn">Get Aadhaar OTP</button>
            <button class="btn btn-sm btn-success" (click)="resendOtp()" id="Btn" *ngIf="resend_otp" [disabled]="!enableBtn">Resend Aadhaar OTP</button>&nbsp; -->
            
        </div>
    </div>
    <div class="col-xl-4 mb-2">
        <div class="form-group">
            <div>
                <label class="control-label">Enter OTP </label>
                <input type="text" autocomplete="off" maxlength="6" formControlName="otp"
                    onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                    class="form-control form-control-sm">
            </div>
        </div>
        <button class="btn btn-sm btn-success" [disabled]="entrOTP" id="Btn2"
                            (click)="verifyOTP('ver')">Submit OTP</button>
    </div>
    <div class="col-xl-4">
        <div class="form-group">
            <div>
                <label class="control-label">Mobile Number </label>
                <input type="text" autocomplete="off" maxlength="10" formControlName="mobileNumber"
                    onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                    class="form-control form-control-sm">
            </div>
        </div>
        {{displayTimer}}
        <button class="btn btn-sm btn-success" id="Btn3" [attr.disabled]="isDisableMobOtp?true:null"
            (click)="generateOTP()">Generate OTP</button>
            <!-- <button class="btn btn-sm btn-success" id="Btn3" *ngIf="!resendMobileOtp" [disabled]="genOTP" 
            (click)="generateOTP()">Generate OTP</button> -->
            <!-- <button class="btn btn-sm btn-success" id="Btn3" *ngIf="resendMobileOtp" [disabled]="genOTP"
            (click)="reGenerateOtp()">ReGenerate OTP</button> -->
        
    </div>
    <div class="col-xl-4" *ngIf="!mobileLinked">
        <div class="form-group">
            <div>
                <label class="control-label">Enter OTP</label>
                <input type="text" autocomplete="off" maxlength="6" formControlName="verotp"
                    onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                    class="form-control form-control-sm">
            </div>
        </div>
        <button class="btn btn-sm btn-success" id="Btn4"  (click)="verifiedMobile()">Submit
            OTP</button>

    </div>

    <div class="col-xl-4" *ngIf="suggestionList.length">
        <div class="form-group">
 <label>Choose Suggested HPR Id</label>
        <input type="text" autocomplete="off" maxlength="20" formControlName="HprofessionalId"
        class="form-control form-control-sm" (input)="selectedHprIdInput($event.target.value)">
        <div class="input-group">
            <label class="control-label">Suggestions - &nbsp;</label>
            <span *ngFor="let d of suggestionList" (click)="selectedHprId(d)" style="color: #1b6d9c; font-weight: bold;cursor: pointer;">{{ d }},</span>
        </div>
          
      
        <!-- <div *ngFor="let item of suggestionList">
            <input type="radio" formControlName="suggestionId" id="item" name="item" (click)="onChangesuggestion(item)">&nbsp;{{item}}
        </div> --> 
        </div>
       
    </div>

            <div class="col-xl-4 mb-2">
                <label> First Name :<span class="text-danger">*</span></label>
                <input type="text" placeholder="Enter First Name" class="form-control form-control-sm" 
                formControlName="firstName" [ngClass]="{ 'is-invalid': submitted1 && fc.firstName.errors }">
                <div *ngIf="submitted1 && fc.firstName.errors" class="invalid-feedback">
                    <div *ngIf="fc.firstName.errors.required">First Name  is required</div>
                </div>
            </div> 
            <div class="col-xl-4 mb-2">
                <label> Middle Name :</label>
                <input type="text" placeholder="Enter Middle Name" class="form-control form-control-sm" 
                formControlName="middleName" >
            </div> 
        </div>

        <div class="row">
                <div class="col-xl-3 mb-2">
                    <label>Last Name :</label>
                    <input type="text" placeholder="Enter Last Name" class="form-control form-control-sm" 
                    formControlName="lastName" >
                 
                </div> 
                <!-- <div class="col-xl-3 mb-2">
                    <label>Healthcare Professional ID :<span class="text-danger">*</span></label>
                    <input type="text" placeholder="Enter Last Name" class="form-control form-control-sm" disabled
                    formControlName="HprofessionalId" [ngClass]="{ 'is-invalid': submitted1 && fc.HprofessionalId.errors }">
                    <div *ngIf="submitted1 && fc.HprofessionalId.errors" class="invalid-feedback">
                        <div *ngIf="fc.HprofessionalId.errors.required">Last Name  is required</div>
                    </div>
                </div>  -->
                <div class="col-xl-3 mb-2">
                    <label>Mobile Number :<span class="text-danger">*</span></label>
                    <input type="text" placeholder="Enter Mobile Number" maxlength="10" class="form-control form-control-sm" 
                    formControlName="mobile" [ngClass]="{ 'is-invalid': submitted1 && fc.mobile.errors }">
                    <div *ngIf="submitted1 && fc.mobile.errors" class="invalid-feedback">
                        <div *ngIf="fc.mobile.errors.required">Mobile Number is required</div>
                    </div>
                </div>
                <div class="col-xl-3 mb-2">
                    <label>Date Of Birth :<span class="text-danger">*</span></label>
                    <input type="text" placeholder="DD-MM-YYYY" class="form-control form-control-sm" 
                    formControlName="birthDate"  [ngClass]="{ 'is-invalid': submitted1 && fc.birthDate.errors }"
                    bsDatepicker #dp="bsDatepicker" (focus)="dp.show()" 
                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }">
                    <div *ngIf="submitted1 && fc.birthDate.errors" class="invalid-feedback">
                        <div *ngIf="fc.birthDate.errors.required">Date Of Birth is required</div>
                    </div>
                </div>
                <div class="col-xl-3 mb-2">
                    <label>Gender :<span class="text-danger">*</span></label>
                    <select formControlName="gender" id="gender"
                    class="form-control form-control-sm" >
                    <option value="">Select</option>
                    <option value="F">Female</option>
                    <option value="M">Male</option>
                    <option value="T">Transgender</option>   
                </select>
                </div>
                <div class="col-xl-3 mb-2">
                    <label>Health Profession Category :<span class="text-danger">*</span></label>
                    <select formControlName="healthProfessionalType" id="healthProfessionalType"  (change)="healthSubCategory($event.target.value)"
                    class="form-control form-control-sm" [ngClass]="{ 'is-invalid': submitted1 && fc.healthProfessionalType.errors }">
                    <option value="">Select</option>
                    <option value="administator">ADMINSTRATOR</option>
                    <option value="doctor">DOCTOR</option>
                    <option value="manufacturer">MANUFACTURER</option>
                    <option value="nurse">NURSE</option> 
                </select>
                <div *ngIf="submitted1 && fc.healthProfessionalType.errors" class="invalid-feedback">
                    <div *ngIf="fc.healthProfessionalType.errors.required">Health profession Category is required</div>
                </div>
                </div>
                <div class="col-xl-3 mb-2">
                    <label>Health Profession Sub Category :</label>
                    <select formControlName="healthProfessionalSubType" id="healthProfessionalSubType"
                    class="form-control form-control-sm" >
                    <option value="">Select</option>
                    <option *ngFor="let item of subCategoryList" [value]="item.code">
                        {{item?.name}}</option>
                </select>
                </div>
                <div class="col-xl-3 mb-2">
                    <label>Password :<span class="text-danger">*</span></label>
                    <input type="password" placeholder="Enter Password" class="form-control form-control-sm" 
                    formControlName="password" [ngClass]="{ 'is-invalid': submitted1 && fc.password.errors }"
                    (keyup)="patternCheck($event.target.value)">
                    <div *ngIf="submitted1 && fc.password.errors" class="invalid-feedback">
                        <div *ngIf="fc.password.errors.required">Password  is required</div>
                    </div>
                </div> 
                <div class="col-xl-3 mb-2">
                    <label>Confirm Password :</label>
                    <input type="password" placeholder="Enter Password" class="form-control form-control-sm" 
                    formControlName="confirmpassword" [ngClass]="{ 'is-invalid': submitted1 && fc.confirmpassword.errors }"
                    (keyup)="patternCheck($event.target.value)">
                    <div *ngIf="submitted1 && fc.confirmpassword.errors" class="invalid-feedback">
                        <div *ngIf="fc.confirmpassword.errors.required">Confirm Password is required</div>
                    </div>
                </div>
                <div class="col-xl-3 mb-2">
                    <label>Address :<span class="text-danger">*</span></label>
                    <textarea rows="2" class="form-control form-control-sm" 
                    id="home_address" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                    (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32) || (event.charCode == 44)|| 
                    (event.charCode == 45)|| (event.charCode == 47)) ||  (event.charCode >= 48 && event.charCode <= 57)'
                    maxlength="100"  formControlName="address"
                   ></textarea>
                </div> 
                <div class="col-xl-3 mb-2">
                    <label>Email :<span class="text-danger">*</span></label>
                    <input type="text" placeholder="Enter Email" class="form-control form-control-sm" 
                    formControlName="email" [ngClass]="{ 'is-invalid': submitted1 && fc.email.errors }">
                    <div *ngIf="submitted1 && fc.email.errors" class="invalid-feedback">
                        <div *ngIf="fc.email.errors.required">Email Address  is required</div>
                    </div>
                </div> 
                <div class="col-xl-3 mb-2">
                    <label>State :<span class="text-danger">*</span></label>
                    <select formControlName="state" id="state" [ngClass]="{ 'is-invalid': submitted1 && fc.state.errors }"
                    class="form-control form-control-sm" (change)="districtApi($event.target.value)">
                    <option value="">Select</option>
                    <option *ngFor="let item of hprStateList" [value]="item.code">
                        {{item.name}}</option>
                </select>
                <div *ngIf="submitted1 && fc.state.errors" class="invalid-feedback">
                    <div *ngIf="fc.state.errors.required">State is required</div>
                </div>
                </div>
                <div class="col-xl-3 mb-2">
                    <label>District :<span class="text-danger">*</span></label>
                    <select formControlName="district" id="district" [ngClass]="{ 'is-invalid': submitted1 && fc.district.errors }"
                    class="form-control form-control-sm">
                    <option value="">Select</option>
                    <option *ngFor="let item of hprDistrictList" [value]="item.code">
                        {{item.name}}</option>
                </select>
                <div *ngIf="submitted1 && fc.district.errors" class="invalid-feedback">
                    <div *ngIf="fc.district.errors.required">District is required</div>
                </div>
                </div>
                <div class="col-xl-3 mb-2">
                    <label>Pincode :<span class="text-danger">*</span></label>
                    <input type="text" placeholder="Enter Pincode" maxlength="6" class="form-control form-control-sm" 
                    formControlName="postalCode" [ngClass]="{ 'is-invalid': submitted1 && fc.postalCode.errors }">
                    <div *ngIf="submitted1 && fc.postalCode.errors" class="invalid-feedback">
                        <div *ngIf="fc.postalCode.errors.required">Pincode is required</div>
                    </div>
                </div>
               
         </div>

        <div class="text-center mt-4 mb-4" *ngIf="isSubmitButton">
                <input type="submit"  class="btn btn-sm btn-primary" (click)="createHprIdWithPreVerified()"
                    value="Submit"> 
        </div>
    </div>
    <hr>
</div>

          
<div *ngIf="fillAllFields">
    <mat-horizontal-stepper>
        <mat-step label="Personal Details">
            <div [formGroup]="hprForm2">
                <h6> <strong>Additional Personal Details</strong> </h6>
            <div class="row mt-4">
                <div class="col-xl-3 mb-2">
                    <label>Salutation :<span class="text-danger">*</span></label>
                    <select formControlName="salutation" id="salutation"
                    class="form-control form-control-sm" 
                    [ngClass]="{ 'is-invalid': submitted1 && gc.salutation.errors }">
                    <option value="">Select</option>
                    <option *ngFor="let item of salutationList" [value]="item.ndhm_code">
                        {{item?.title_name}}</option>
                </select>
                <div *ngIf="submitted1 && gc.salutation.errors" class="invalid-feedback">
                    <div *ngIf="gc.salutation.errors.required">Salutation is required</div>
                </div>
                </div>

                <div class="col-xl-7">
                    <label>Do you want to show a different public profile picture ?</label>
                    <div>
                        <input type="radio" formControlName="publicProfile"  [value]="true" disabled>Yes
                        <input type="radio" formControlName="publicProfile"  [value]="false" disabled>No
                    </div>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col-xl-3 mb-2">
                    <label> First Name(for public display) :<span class="text-danger">*</span></label>
                    <input type="text" placeholder="Enter First Name" class="form-control form-control-sm" 
                    formControlName="firstName" [ngClass]="{ 'is-invalid': submitted1 && gc.firstName.errors }">
                    <div *ngIf="submitted1 && gc.firstName.errors" class="invalid-feedback">
                        <div *ngIf="gc.firstName.errors.required">First Name  is required</div>
                    </div>
                </div> 
                <div class="col-xl-3 mb-2">
                    <label> Middle Name(for public display) :</label>
                    <input type="text" placeholder="Enter Middle Name" class="form-control form-control-sm" 
                    formControlName="middleName" >
                </div> 
                <div class="col-xl-3 mb-2">
                    <label>Last Name(for public display) :</label>
                    <input type="text" placeholder="Enter Last Name" class="form-control form-control-sm" 
                    formControlName="lastName">
                 
                </div> 
                <div class="col-xl-3 mb-2">
                    <label> Father's Name :</label>
                    <input type="text" placeholder="Enter Father Name" class="form-control form-control-sm" 
                    formControlName="fatherName">
             
                </div> 
                <div class="col-xl-3 mb-2">
                    <label> Mother's Name :</label>
                    <input type="text" placeholder="Enter Mother Name" class="form-control form-control-sm" 
                    formControlName="motherName" >
                </div> 
                <div class="col-xl-3 mb-2">
                    <label>Spouse's Name :</label>
                    <input type="text" placeholder="Enter Spouse Name" class="form-control form-control-sm" 
                    formControlName="spouseName" > 
                </div> 
                <div class="col-xl-3 mb-2">
                    <label>Nationalty :<span class="text-danger">*</span></label>
                    <select formControlName="nationality" id="nationality" 
                    class="form-control form-control-sm" disabled>
                    <option value="">Select</option>
                    <option value="1">INDIA</option>
                </select>
                </div>
                <div class="col-xl-3 mb-2">
                    <label>languages spoken :<span class="text-danger">*</span></label>
                    <ng-multiselect-dropdown [settings]="dropdownSettings_spokenLanguages"
                    formControlName="languagesKnown" [placeholder]="'Select'"
                    (onSelect)="onItemSelectCategory($event)"
                    (onDeSelect)="onItemDeSelectCategory($event)" [data]="spokenLanguagesList"
                    [ngClass]="{ 'is-invalid': submitted1 && gc.languagesKnown.errors }">
                    <!-- <option *ngFor="let item of dropdownList1" value="item.category_id">{{ item.category_name}}</option> -->
                </ng-multiselect-dropdown>
                <div *ngIf="submitted1 && gc.languagesKnown.errors" class="invalid-feedback">
                    <div *ngIf="gc.languagesKnown.errors.required">languages known is required</div>
                </div>
                </div>

            </div>

             <div class="row mb-4">
                <div class="col-xl-6">
                    <h6><strong>Address as per KYC :</strong></h6>
                    <div>
                        {{addressAsPerKyc}}
                    </div>
                </div>
                <div class="col-xl-3"></div>
                <div class="col-xl-3" *ngIf="isAddressVerified">Aadhaar Verified </div>
                <div class="col-xl-3" *ngIf="!isAddressVerified">Aadhaar Not Verified </div>
             </div>
             <div class="row mb-4">
                <h6><strong>Communication Address</strong></h6>
             </div>
             <div class="row">
                <div class="col-xl-7">
                    Is this communication address same as your address as per KYC ? 
                </div>
                <div class="col-xl-1">
                    <input type="checkbox"  formControlName="asPerKYCComAdd" (checked)="asPerKYCComAdd()">
                </div>
             </div>
             <div class="row">
                <div class="col-xl-3 mb-2">
                    <label>Name :<span class="text-danger">*</span></label>
                    <input type="text" placeholder="Enter Name" class="form-control form-control-sm" 
                    [ngClass]="{ 'is-invalid': submitted1 && gc.NameAsPerKyc.errors }"
                    formControlName="NameAsPerKyc">
                    <div *ngIf="submitted1 && gc.NameAsPerKyc.errors" class="invalid-feedback">
                        <div *ngIf="gc.NameAsPerKyc.errors.required">Name is required</div>
                    </div>
                </div>
                <div class="col-xl-3 mb-2">
                    <label>Address :<span class="text-danger">*</span></label>
                    <textarea rows="2" class="form-control form-control-sm" 
                    id="address" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                    (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32) || (event.charCode == 44)|| 
                    (event.charCode == 45)|| (event.charCode == 47)) ||  (event.charCode >= 48 && event.charCode <= 57)'
                    maxlength="100"  formControlName="address"
                    [ngClass]="{ 'is-invalid': submitted1 && gc.address.errors }"
                   ></textarea>
                   <div *ngIf="submitted1 && gc.address.errors" class="invalid-feedback">
                    <div *ngIf="gc.address.errors.required">Address is required</div>
                </div>
                </div>

                <div class="col-xl-3 mb-2">
                    <label>Country :<span class="text-danger">*</span></label>
                    <select formControlName="country" id="country" 
                    class="form-control form-control-sm"
                    [ngClass]="{ 'is-invalid': submitted1 && gc.country.errors }">
                    <option value="">Select</option>
                    <option value="1">INDIA</option>
                </select>
                <div *ngIf="submitted1 && gc.country.errors" class="invalid-feedback">
                    <div *ngIf="gc.country.errors.required">Country is required</div>
                </div>
            
                </div>
                <div class="col-xl-3 mb-2">
                    <label>State :<span class="text-danger">*</span></label>
                    <select formControlName="state" id="state" 
                    class="form-control form-control-sm" (change)="districtApi($event.target.value)"
                    [ngClass]="{ 'is-invalid': submitted1 && gc.state.errors }">
                    <option value="">Select</option>
                    <option *ngFor="let item of hprStateList" [value]="item.code">
                        {{item.name}}</option>
                </select>
                <div *ngIf="submitted1 && gc.state.errors" class="invalid-feedback">
                    <div *ngIf="gc.state.errors.required">State is required</div>
                </div>
            
                </div>
                <div class="col-xl-3 mb-2">
                    <label>District :<span class="text-danger">*</span></label>
                    <select formControlName="district" id="district" (change)="subDistrictApi($event.target.value)"
                    class="form-control form-control-sm"
                    [ngClass]="{ 'is-invalid': submitted1 && gc.district.errors }">
                    <option value="">Select</option>
                    <option *ngFor="let item of hprDistrictList" [value]="item.code">
                        {{item.name}}</option>
                </select>
                <div *ngIf="submitted1 && gc.district.errors" class="invalid-feedback">
                    <div *ngIf="gc.district.errors.required">District is required</div>
                </div>
                </div>

                <div class="col-xl-3 mb-2">
                    <label>Sub District :</label>
                    <select formControlName="subDistrict" id="subDistrictCode"
                    class="form-control form-control-sm">
                    <option value="">Select</option>
                    <option *ngFor="let item of hprSubDistrictList" [value]="item.code">
                        {{item.name}}</option>
                </select>
                </div> 
                <div class="col-xl-3 mb-2">
                    <label>Village :</label>
                    <select formControlName="village" id="village"
                    class="form-control form-control-sm">
                    <option value="">Select</option>
                    <!-- <option *ngFor="let item of hprSubDistrictList" [value]="item.code">
                        {{item.name}}</option> -->
                </select>
                </div> 
                <div class="col-xl-3 mb-2">
                    <label>Pincode :<span class="text-danger">*</span></label>
                    <input type="text" placeholder="Enter Pincode" maxlength="6" class="form-control form-control-sm" 
                    formControlName="postalCode" [ngClass]="{ 'is-invalid': submitted1 && gc.postalCode.errors }">
                    <div *ngIf="submitted1 && gc.postalCode.errors" class="invalid-feedback">
                        <div *ngIf="gc.postalCode.errors.required">Pincode is required</div>
                    </div>
                </div>

             </div>

             <div class="row mt-4 mb-4">
                <h6><Strong>Contact Information</Strong></h6>
            </div>

             <div class="row mt-4 mb-4">
 
                 <div class="col-xl-4">
 
                             <label>Should your public mobile number be same as your official mobile number</label>
                             <div>
                                 <input type="radio" formControlName="publicMobileNumberStatus"  [value]="1" (change)="changePublicMb($event.target.value)">&nbsp;Yes&nbsp;
                                 <input type="radio" formControlName="publicMobileNumberStatus"  [value]="0" (change)="changePublicMb($event.target.value)">&nbsp;No
                         </div>
                        
                </div>

                <div class="col-xl-4">
                         <label>Should your public E-Mail ID be same as your official Email Communication</label>
                         <div>
                             <input type="radio" formControlName="publicEmailStatus" [value]="1" (change)="changePublicEmail($event.target.value)">&nbsp;Yes&nbsp;
                             <input type="radio" formControlName="publicEmailStatus"  [value]="0" (change)="changePublicEmail($event.target.value)">&nbsp;No
                     </div>
                    
                 </div>       
             </div>

             <div class="row mb-4">
                     <div class="col-xl-4 mt-2 mb-2">
                         <label>Mobile Number (For public Display)</label>
                         <div>
                             <input type="text"  class="form-control form-control-sm" formControlName="publicMobileNumber" 
                             placeholder="Mobile Number" maxlength="10" [attr.disabled]="mobileField?true:null">
                         </div>
                     </div>
                     <div class="col-xl-4 mt-2 mb-2">
                         <label>EMail Id (For public Display)</label>
                         <div>
                             <input type="text"  class="form-control form-control-sm" formControlName="publicEmail" placeholder="Email"  
                             [attr.disabled]="emailField?true:null">
                         </div>
                     </div>
                     <div class="col-xl-4 mt-2 mb-2">
                         <label>Landline Number (For public Display)</label>
                         <div>
                             <input type="text"  class="form-control form-control-sm" formControlName="landLineNumber" placeholder="Landline Number" maxlength="15">
                         </div>
                     </div>
                 
            </div>
            
        </div>

          <button class="btn btn-sm btn-primary" (click)="prsnldetailSave()">Save</button>&nbsp;
          <!-- [disabled]="!hprForm2.valid" -->
          <button mat-button matStepperNext class="btn btn-sm btn-primary" >Next</button>
        </mat-step>

            <mat-step label="Registration & Academic Details">
            <div [formGroup]="hprForm3">

            <div class="row mt-4 mb-4">
                <h6><strong>System Of Medicine (For Public Display)</strong></h6>
            </div>
            <div class="row mb-4">
            <div class="col-xl-6 mb-2">
                <label>System Of Medicine :<span class="text-danger">*</span></label>
                <ng-multiselect-dropdown [settings]="dropdownSettings_Medicine"
                formControlName="medicine" [placeholder]="'Select'"
                (onSelectAll)="onItemSelectAllSysMedicine($event)"
                (onDeSelectAll)="onItemDESelectAllSysMedicine($event)"
                (onSelect)="onItemSelectSysMedicine($event)"
                (onDeSelect)="onItemDeSelectSysMedicine($event)" [data]="medicineList"
                [ngClass]="{ 'is-invalid': submitted1 && k.medicine.errors }">
            </ng-multiselect-dropdown>
            <div *ngIf="submitted1 && k.medicine.errors" class="invalid-feedback">
                <div *ngIf="k.medicine.errors.required">System Of Medicine is required</div>
            </div>
            </div>
            </div>
            <div class="row mb-4">
            <h6><strong>Registration Details (Modern Medicine)</strong></h6>
            </div>
            <div class="row mb-4">
            <div class="col-xl-4 mb-4">
                <label>Registered With Council :<span class="text-danger">*</span></label>
                <select formControlName="registeredWithCouncil" id="registeredWithCouncil"
                class="form-control form-control-sm" [ngClass]="{ 'is-invalid': submitted1 && k.registeredWithCouncil.errors }">
                <option value="">Select</option>
                <option *ngFor="let item of councilList" [value]="item.id">
                    {{item?.council_name}}</option>
            </select>
            <div *ngIf="submitted1 && k.registeredWithCouncil.errors" class="invalid-feedback">
                <div *ngIf="k.registeredWithCouncil.errors.required">Registered With Council is required</div>
            </div>
            </div>
            <div class="col-xl-4 mb-4">
                <label>MCI number :<span class="text-danger">*</span></label>
                <input type="text" placeholder="Enter MCI number" class="form-control form-control-sm" 
                formControlName="registrationNumber" [ngClass]="{ 'is-invalid': submitted1 && k.registrationNumber.errors }">
                <div *ngIf="submitted1 && k.registrationNumber.errors" class="invalid-feedback">
                    <div *ngIf="k.registrationNumber.errors.required">MCI number is required</div>
                </div>
            </div>
            <div class="col-xl-4 mb-4">
                <label>Registration Date(If Available)</label>
                <input type="text" placeholder="DD-MM-YYYY" class="form-control form-control-sm" 
                formControlName="registrationDate"  
                bsDatepicker #dp="bsDatepicker" (focus)="dp.show()" 
                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }">

            </div>
            <div class="col-xl-4 mb-4">
                <label>Is this registration permanent or renewable<span class="text-danger">*</span></label>
                <div>
                    <input type="radio" formControlName="isPermanentOrRenewable"  value="Permanent" (click)="changePorR($event.target.value)">&nbsp;Permanent&nbsp;
                    <input type="radio" formControlName="isPermanentOrRenewable"  value="Renewable" (click)="changePorR($event.target.value)">&nbsp;Renewable
            </div>
            </div>
            <div class="col-xl-4 mb-4" *ngIf="renewableDateEnable">
                <label>Due date of renewal :</label>
                <input type="text" placeholder="DD-MM-YYYY" class="form-control form-control-sm" 
                formControlName="renewableDueDate"  
                bsDatepicker #dp="bsDatepicker" (focus)="dp.show()" 
                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }">

            </div>
            <div class="col-xl-4 mb-4">
                <label>Registration Certificate Attachment</label>
                <input type="file" (change)="onFileSelected($event)" class="form-control form-control-sm"  accept=".pdf,.png,.jpeg,.jpg" />
                <div *ngIf="fileSizeError" class="error-message">Max Allowed File Size 5 MB. (Allowed Formats : PDF, PNG, JPEG, JPG)</div>
                                               
              </div>
        <div class="col-xl-8 mb-4">
            <label>Is your name in registration certificate,different from your name in aadhar<span class="text-danger">*</span></label>
            <div>
                <input type="radio" formControlName="isNameDifferentInCertificate"  value="1" (click)="uploadCertificate($event.target.value)">&nbsp;Yes&nbsp;
                <input type="radio" formControlName="isNameDifferentInCertificate"  value="0" (click)="uploadCertificate($event.target.value)">&nbsp;No
        </div>
        </div>
        <div class="col-xl-4 mb-4" *ngIf="uploadcer">
            <label>Upload Proof Of Name Change<span class="text-danger">*</span></label>
          <div>
            <input type="file" (change)="onFileSelected($event)" class="form-control form-control-sm"  accept=".pdf,.png,.jpeg,.jpg" />
            <div *ngIf="fileSizeError" class="error-message">(upload copy of Gazette of india or other legal document where your name change is reflected)
                Max Allowed File Size 5 MB. (Allowed Formats : PDF, PNG, JPEG, JPG)</div>
          </div>
           
                                           
          </div>
            </div>
            <div class="row mb-4">
                <h6><strong>Basic Qualification Details (Modern Medicine)</strong></h6>
            </div>
           <div class="row">
            <div class="col-xl-4 mb-2">
                <label>Name Of Degree or Diploma Obtained(for public display) :<span class="text-danger">*</span></label>
                <select formControlName="qualifications" id="qualifications"
                class="form-control form-control-sm" 
                [ngClass]="{ 'is-invalid': submitted1 && k.qualifications.errors }">
                <option value="">Select</option>
                <option *ngFor="let item of hprqualificationList" [value]="item.id">
                    {{item?.qualification}}</option>
            </select>
            <div *ngIf="submitted1 && k.qualifications.errors" class="invalid-feedback">
                <div *ngIf="k.qualifications.errors.required"> Degree is required</div>
            </div>
            </div>
            <div class="col-xl-4 mb-2">
                <label>Country :<span class="text-danger">*</span></label>
                <select formControlName="country" id="country" 
                class="form-control form-control-sm"
                [ngClass]="{ 'is-invalid': submitted1 && k.country.errors }">
                <option value="">Select</option>
                <option value="1">INDIA</option>
            </select>
            <div *ngIf="submitted1 && k.country.errors" class="invalid-feedback">
                <div *ngIf="k.country.errors.required">Country is required</div>
            </div>
        
            </div>
            <div class="col-xl-4 mb-2">
                <label>State :<span class="text-danger">*</span></label>
                <select formControlName="state" id="state" 
                class="form-control form-control-sm" (change)="districtApi($event.target.value)"
                [ngClass]="{ 'is-invalid': submitted1 && k.state.errors }">
                <option value="">Select</option>
                <option *ngFor="let item of hprStateList" [value]="item.code">
                    {{item.name}}</option>
            </select>
            <div *ngIf="submitted1 && k.state.errors" class="invalid-feedback">
                <div *ngIf="k.state.errors.required">State is required</div>
            </div>


            </div>
            <div class="col-xl-4 mb-2">
                <label>College :<span class="text-danger">*</span></label>
                <select formControlName="college" id="college"(focus)="collegeDD();"  (change)="universityDD($event.target.value)" 
                class="form-control form-control-sm" [ngClass]="{ 'is-invalid': submitted1 && k.college.errors }">
                <option value="">Select</option>
                <option *ngFor="let item of collegeList" [value]="item.college_id">
                    {{item?.college_name}}</option>
            </select>
            <div *ngIf="submitted1 && k.college.errors" class="invalid-feedback">
                <div *ngIf="k.college.errors.required">College is required</div>
            </div>
            </div>
            <div class="col-xl-4 mb-2">
                <label>University :<span class="text-danger">*</span></label>
                <select formControlName="university" id="university" 
                class="form-control form-control-sm" [ngClass]="{ 'is-invalid': submitted1 && k.university.errors }">
                <option value="">Select</option>
                <option *ngFor="let item of universityList" [value]="item.university_id">
                    {{item?.university_name}}</option>
            </select>
            <div *ngIf="submitted1 && k.university.errors" class="invalid-feedback">
                <div *ngIf="k.university.errors.required">University is required</div>
            </div>
            </div>
            <div class="col-xl-4 mb-2">
                <label>Month Of Awarding degree Or Diploma :</label>
                <select formControlName="monthOfAward" id="monthOfAward" 
                class="form-control form-control-sm" >
                <option value="">Select</option>
            </select>
           </div>
            <div class="col-xl-4 mb-2">
                <label>Year Of Awarding degree Or Diploma :</label>
                <input type="date" formControlName="yearOfAwardingDegreeDiploma" id="yearOfAwardingDegreeDiploma" 
                class="form-control form-control-sm" >
           </div>
           <div class="col-xl-4 mb-4">
            <label>Degree Attachment</label>
            <input type="file" (change)="onFileSelected($event)" class="form-control form-control-sm"  accept=".pdf,.png,.jpeg,.jpg" />
            <div *ngIf="fileSizeError" class="error-message">Max Allowed File Size 5 MB. (Allowed Formats : PDF, PNG, JPEG, JPG)</div>
                                           
          </div>
          <div class="col-xl-8 mb-4">
            <label>Is your name in Degree,different from your name in aadhar<span class="text-danger">*</span></label>
            <div>
                <input type="radio" formControlName="isNameDifferentInDegree"  value="1" (click)="uploadDegree($event.target.value)">&nbsp;Yes&nbsp;
                <input type="radio" formControlName="isNameDifferentInDegree"  value="0" (click)="uploadDegree($event.target.value)">&nbsp;No
        </div>
        </div>
        <div class="col-xl-4 mb-4" *ngIf="uploadDeg">
            <label>Upload Proof Of Name Change<span class="text-danger">*</span></label>
          <div>
            <input type="file" (change)="onFileSelected($event)" class="form-control form-control-sm"  accept=".pdf,.png,.jpeg,.jpg" />
            <div *ngIf="fileSizeError" class="error-message">(upload copy of Gazette of india or other legal document where your name change is reflected)
                Max Allowed File Size 5 MB. (Allowed Formats : PDF, PNG, JPEG, JPG)</div>
          </div>
           
                                           
          </div>


            </div>
            </div>

          <button mat-button matStepperPrevious class="btn btn-sm btn-primary">Back</button>&nbsp;
          <button class="btn btn-sm btn-primary" (click)="regDetailSave()">Save</button>&nbsp;
          <!-- [disabled]="!hprForm3.valid" -->
          <button mat-button matStepperNext class="btn btn-sm btn-primary">Next</button>
        </mat-step>
        <mat-step label="Work Details">
            <div [formGroup]="hprForm4">
           <div class="row mt-4 mb-4">
            <h6><strong>Current Work Detaials</strong></h6>
           </div>
           <div class="row mb-4">
            <div class="col-xl-4">
                <label>Are You Currently Working?<span class="text-danger">*</span></label>
                <div>
                    <input type="radio" formControlName="currentlyWorking"  value="1" (click)="isstatusWorking($event.target.value)">&nbsp;Yes&nbsp;
                    <input type="radio" formControlName="currentlyWorking"  value="0" (click)="isstatusWorking($event.target.value)">&nbsp;No
                </div>
            </div>
            <div class="col-xl-4" *ngIf="workFieldsEnable">
                <label>Nature Of Work<span class="text-danger">*</span></label>
                <div>
                    <select formControlName="purposeOfWork" id="purposeOfWork" 
                    class="form-control form-control-sm" 
                    [ngClass]="{ 'is-invalid': submitted1 && m.purposeOfWork.errors }">
                    <option value="">Select</option>
                </select>
                <div *ngIf="submitted1 && m.purposeOfWork.errors" class="invalid-feedback">
                    <div *ngIf="k.purposeOfWork.errors.required">Nature Of Work is required</div>
                </div>
                </div>
            </div>
            <div class="col-xl-4" *ngIf="!workFieldsEnable">
                <label>Please select reason for presently not working *<span class="text-danger">*</span></label>
                <div>
                    <select formControlName="purposeOfNotWork" id="purposeOfNotWork" 
                    class="form-control form-control-sm" 
                    [ngClass]="{ 'is-invalid': submitted1 && m.purposeOfNotWork.errors }">
                    <option value="">Select</option>
                </select>
                <div *ngIf="submitted1 && m.purposeOfNotWork.errors" class="invalid-feedback">
                    <div *ngIf="k.purposeOfNotWork.errors.required">Field is required</div>
                </div>
                </div>
            </div>
           </div>
           <div class="row mb-4" *ngIf="workFieldsEnable">
           <div class="col-xl-4 mb-2">
            <label>Choose Work Status<span class="text-danger">*</span></label>
            <div>
                <input type="radio" formControlName="chooseWorkStatus"  value="1" (click)="isWorkingStatus($event.target.value)">&nbsp;Government only&nbsp;
                <input type="radio" formControlName="chooseWorkStatus"  value="0" (click)="isWorkingStatus($event.target.value)">&nbsp;Private Practice only&nbsp;
                <input type="radio" formControlName="chooseWorkStatus"  value="2" (click)="isWorkingStatus($event.target.value)">&nbsp;Both
            </div>
           </div>
           <div class="col-xl-4 mb-4" *ngIf="workStatus">
            <label>Attachment<span class="text-danger">*</span></label>
          <div>
            <input type="file" formControlName="attachment" (change)="onFileSelected($event)" class="form-control form-control-sm"  
            [ngClass]="{ 'is-invalid': submitted1 && m.attachment.errors }" accept=".pdf,.png,.jpeg,.jpg" />
            <div *ngIf="fileSizeError" class="error-message">(upload copy of Gazette of india or other legal document where your name change is reflected)
                Max Allowed File Size 5 MB. (Allowed Formats : PDF, PNG, JPEG, JPG)          
          </div>  
          <div>
            Please upload proof that you work for government such as Appointment Letter, Recent Pay-slip, Recent Transfer Order etc
        </div>                                         
          </div>
          <div *ngIf="submitted1 && m.attachment.errors" class="invalid-feedback">
            <div *ngIf="k.attachment.errors.required">Attachment is required</div>
        </div>
           </div>


         </div>

         <div class="row" *ngIf="workFieldsEnable">
            <h6><strong>Place Of Work (For Public Display)</strong></h6>
         </div>
         <div class="row" *ngIf="workFieldsEnable">
            <div class="col-xl-4">
                <label>Would you Like To Add Facility</label>
                <div>
                    <input type="radio"  formControlName="facility" value="Yes" (click)="addFacility($event.target.value)">&nbsp;Yes
                    <input type="radio"  formControlName="facility" value="No" (click)="addFacility($event.target.value)">&nbsp;No
                </div>
            </div>
            <div class="col-xl-4">
                <label>Would you Like To Add Organization</label>
                <div>
                    <input type="radio"  formControlName="organization" value="Yes" (click)="addOrganization($event.target.value)">&nbsp;Yes
                    <input type="radio"  formControlName="organization" value="No" (click)="addOrganization($event.target.value)">&nbsp;No
                </div>
            </div>
         </div>

         <div class="row mt-4 mb-4" *ngIf="facilityselection">
            <h6 *ngIf="facilityselection"><Strong>SELECT FACILITY</Strong></h6>
         </div>

         <div class="row mt-4" *ngIf="facilityselection">
            <div class="col-xl-2">
                <label class="control-label"> Search With <span class="text-danger">*</span></label>
            </div>
                <div class="col-xl-9">
                    <input type="radio" formControlName="searchWith" value="true" (click)="isSearchWith($event.target.value)">&nbsp;Facility Name
                    <input type="radio" formControlName="searchWith" value="false" (click)="isSearchWith($event.target.value)">&nbsp;Facility Id
                </div>  
        </div>
           </div>
           <div class="row mt-4 mb-4" *ngIf="facilityselection">
            <div class="col-xl-3" *ngIf="isFacilityEnable">
                <label>Ownership :<span class="text-danger">*</span></label>
                <select [(ngModel)]="hprownershipCode"
                class="form-control form-control-sm"
                [ngClass]="{ 'is-invalid': submitted && f.ownershipCode.errors }">
                <option value="">Select</option>
                <option *ngFor="let item of ownerTypeList" [value]="item.code">
                    {{item.value}}</option></select>
            </div>
            <div class="col-xl-3 mb-2" *ngIf="isFacilityEnable">
                <label>State :<span class="text-danger">*</span></label>
                <select [(ngModel)]="stateCode" id="state" 
                class="form-control form-control-sm" (change)="districtApi($event.target.value)">
                <option value="">Select</option>
                <option *ngFor="let item of hprStateList" [value]="item.code">
                    {{item.name}}</option>
            </select>
        
            </div>
            <div class="col-xl-3 mb-2" *ngIf="isFacilityEnable">
                <label>District :<span class="text-danger">*</span></label>
                <select [(ngModel)]="districtCode" id="district" (change)="subDistrictApi($event.target.value)"
                class="form-control form-control-sm">
                <option value="">Select</option>
                <option *ngFor="let item of hprDistrictList" [value]="item.code">
                    {{item.name}}</option>
            </select>
            </div>
            <div class="col-xl-3 mb-2" *ngIf="isFacilityEnable">
                <label>Sub District :</label>
                <select [(ngModel)]="subDistrictCode" id="subDistrictCode"
                class="form-control form-control-sm">
                <option value="">Select</option>
                <option *ngFor="let item of hprSubDistrictList" [value]="item.code">
                    {{item.name}}</option>
            </select>
            </div> 
            <div class="col-xl-3 mb-2" *ngIf="isFacilityEnable">
                <label>Facility Name :<span class="text-danger">*</span></label>
                <input type="text" placeholder="Enter Facility Name" class="form-control form-control-sm" 
                [(ngModel)]="facilityName">
              
            </div>
            <div class="col-xl-3 mb-2" *ngIf="!isFacilityEnable">
                <label>Facility Id :<span class="text-danger">*</span></label>
                <input type="text" placeholder="Enter Facility Id" class="form-control form-control-sm" 
                [(ngModel)]="FacilityId">
            </div>
            <div class="col-xl-3 mt-4">
                <button type="submit" class="btn btn-sm btn-primary mt-2" value="Submit" (click)="facilitySearch()">Search</button>&nbsp;&nbsp;
              </div> 
            <div class="table-responsive" *ngIf="facilitySearchList.length">  
                <table datatable *ngIf="dtRenderF"
                class="table table-striped table-bordered table-sm" style="width: 100%; font-size: 12px;">
                    <thead>
                        <tr>
                           <th>S.No</th>
                           <th>Facility Id</th>
                           <th>Facility Name</th>
                           <th>Facility Designation</th>
                           <th>FaclilityDepartment</th>
                           <th>Address Line 1</th>
                           <th>Select</th> 
                        </tr>
                    </thead>
                    <tbody >
                        <tr *ngFor="let item of facilitySearchList;let i=index;">
                            <td>{{i+1}}</td>
                            <td>{{item?.facilityId}}</td>
                            <td>{{item?.facilityName}}</td>
                            <td>{{item?.facilityType}}</td>
                            <td>{{item?.systemOfMedicine}}</td>
                            <td>{{item?.address}}</td>
                             <td><input type="radio"  name="SelectFacilityRadio"
                                id="SelectFacilityRadio{{i}}" (click)="selectFacilityType(item)"></td>
                        </tr>
                    </tbody>
                </table>
            </div> 
        </div>
        <div class="row mt-4 mb-4" *ngIf="addorganization">
            <div class="col-xl-4 mb-2">
                <label>State :<span class="text-danger">*</span></label>
                <select [(ngModel)]="orgStateCode" id="state" 
                class="form-control form-control-sm" (change)="districtApi($event.target.value)">
                <option value="">Select</option>
                <option *ngFor="let item of hprStateList" [value]="item.code">
                    {{item.name}}</option>
            </select>
        
            </div>
            <div class="col-xl-4 mb-2">
                <label>District :<span class="text-danger">*</span></label>
                <select [(ngModel)]="orgDistrictCode" id="district" (change)="subDistrictApi($event.target.value)"
                class="form-control form-control-sm">
                <option value="">Select</option>
                <option *ngFor="let item of hprDistrictList" [value]="item.code">
                    {{item.name}}</option>
            </select>
            </div> 
            <div class="col-xl-4 mb-2">
                <label>Name Of Organization Where You Work :<span class="text-danger">*</span></label>
                <input type="text" placeholder="Enter" class="form-control form-control-sm" 
                [(ngModel)]="nameOfOrganization">
              
            </div>
            <div class="col-xl-4 mb-2">
                <label>Organization Type(If Applicable) :</label>
                <input type="text" placeholder="Enter" class="form-control form-control-sm" 
                [(ngModel)]="organizationType">
            </div>
            <div class="col-xl-4 mb-2">
                <label>Department :</label>
                <input type="text" placeholder="Enter " class="form-control form-control-sm" 
                [(ngModel)]="departmentName">
              
            </div>
            <div class="col-xl-4 mb-2">
                <label>Designation * :<span class="text-danger">*</span></label>
                <input type="text" placeholder="Enter" class="form-control form-control-sm" 
                [(ngModel)]="designation">
            </div>
            <!-- <div class="col-xl-3 mb-2">
                <label>Specialization :</label>
                <select formControlName="specialization" id=""
                class="form-control form-control-sm" >
                <option value="">Select</option>
                <option *ngFor="let item of hprspecializationList" [value]="item.id">
                    {{item?.specialization}}</option>
            </select>   
         </div> -->
            <div class="col-xl-4 mb-2">
                <label>Address :<span class="text-danger">*</span></label>
                <input type="text" placeholder="Enter" class="form-control form-control-sm" 
                [(ngModel)]="orgAddress">
              
            </div>
            <div class="col-xl-4 mb-2">
                <label>PIN Code :<span class="text-danger">*</span></label>
                <input type="text" placeholder="Enter" maxlength="6" class="form-control form-control-sm" 
                [(ngModel)]="orgPinCode">
            </div>
            <div class="col-xl-4 mt-4">
                <button type="submit" class="btn btn-sm btn-primary mt-2" value="Submit" (click)="addOrganizationTable()">Search</button>&nbsp;&nbsp;
              </div> 
            <div class="table-responsive" *ngIf="addOrgTableData.length">  
                <table datatable *ngIf="dtRenderOR"
                class="table table-striped table-bordered table-sm" style="width: 100%; font-size: 12px;">
                    <thead>
                        <tr>											
                           <th>S.No</th>
                           <th>Facility ID</th>
                           <th>Facility Status</th>
                           <th>Name</th>
                           <th>Address</th>
                           <th>State</th>
                           <th>District</th>
                           <th>Type</th>
                           <th>Department</th>
                           <th>Designation</th>
                           <th>eLoc</th>
                           <th>Status</th>
                           <th>Action</th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr *ngFor="let item of addOrgTableData;let i=index;">
                            <td>{{i+1}}</td>
                            <td>{{item?.FacilityID}}</td>
                            <td>{{item?.FacilityStatus}}</td>
                            <td>{{item?.Name}}</td>
                            <td>{{item?.Address}}</td>
                            <td>{{item?.State}}</td>
                            <td>{{item?.District}}</td>
                            <td>{{item?.Type}}</td>
                            <td>{{item?.Department}}</td>
                            <td>{{item?.Designation}}</td>
                            <td>{{item?.eLoc}}</td>
                            <td>{{item?.Status}}</td>
                            <td>{{item?.Action}}</td>
                             <!-- <td><input type="radio"  name="SelectFacilityRadio"
                                id="SelectFacilityRadio{{i}}" (click)="selectFacilityType(item)"></td> -->
                        </tr>
                    </tbody>
                </table>
            </div> 
        </div>
        <div class="row mt-4 mb-4">
            <h6><strong>Add Teleconsultation URL</strong></h6>
        </div>
        <div class="row mb-4">
            <label>Teleconsultation Url :</label>
            <input type="text" placeholder="Enter" class="form-control form-control-sm" 
            [(ngModel)]="telconsulURL">
        </div>
            <button mat-button matStepperPrevious  class="btn btn-sm btn-primary">Back</button>&nbsp;
            <button class="btn btn-sm btn-primary" (click)="workDetailSave()">Save</button>&nbsp;
            <button mat-button matStepperNext  class="btn btn-sm btn-primary">Next</button>
          </mat-step>
        <mat-step label="Preview Profile">

         <div class="row mt-4 mb-4">
            <div class="col-xl-6">
                <label>Do You Want Your Profile To Be Visible To The Public?<span class="text-danger">*</span></label>
                <div>
                    <input type="radio"  [(ngModel)]="VisblePrfPulc" name="name1" value="Yes">&nbsp;Yes
                    <input type="radio"  [(ngModel)]="VisblePrfPulc" name="name2" value="No" >&nbsp;No
                </div>
            </div>
            <div class="col-xl-6">
                <label>Any One Assisted You To Register In HPR?<span class="text-danger">*</span></label>
                <div>
                    <input type="radio"  [(ngModel)]="assistedHPR" name="nameL" value="Yes">&nbsp;Yes
                    <input type="radio"  [(ngModel)]="assistedHPR" name="namem" value="No">&nbsp;No
                </div>
            </div>
         </div>

         <div class="row mt-4 mb-4">
            <label>Declaration<span class="text-danger">*</span></label>
            <div class="mt-2"><input type="checkbox" [(ngModel)]="declarationBox">&nbsp;&nbsp;I hereby declare that I am voluntarily sharing above mentioned particulars and information. I certify that the above information furnished by me is true, complete, and correct to the best of my knowledge. I understand that in the event of my information being found false or incorrect at any stage, I shall be held liable for the same  </div>
         </div>

          <button mat-button matStepperPrevious class="btn btn-sm btn-primary">Back</button>&nbsp;
          <!-- <button class="btn btn-sm btn-primary" (click)="previewDetailSave()">Save</button>&nbsp; -->
          <button mat-button matStepperNext class="btn btn-sm btn-primary" (click)="previewDetailSave()">save & Submit</button>
        </mat-step>
      </mat-horizontal-stepper>
      <hr>
</div>
    
  
   
        <!-- Link With HPR end -->



        <form [formGroup]="doctorForm" (ngSubmit)="onSubmit($event)">
            <div class="row">
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Type of Doctor <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="doctor_type_id" id="doctor_type_id" #TypeOfDoctor
                                [ngClass]="{ 'is-invalid': submitted && f.doctor_type_id.errors }"
                                class="form-control form-control-sm" (change)="onDoctorChange($event.target.value)"
                                [attr.disabled]="isEditable ? true : null">
                                <option value="">Select</option>
                                <option *ngFor="let item of docTypes" [value]="item.id">
                                    {{item.doctor_type}}</option>
                                <!-- <option value="1" *ngIf="!is1962LiveStock">Hospital</option>
                                <option value="2" *ngIf="!is1962LiveStock">Consultant</option>-->
                                <!-- <option value="4">Ayush</option> -->
                                <!-- <option *ngIf="isGNM" value="3">Genome</option> -->
                            </select>
                            <div *ngIf="submitted && f.doctor_type_id.errors" class="invalid-feedback">
                                <div *ngIf="f.doctor_type_id.errors.required">Type of Doctor is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="hide==1">
                    <div class="form-group">
                        <label class="control-label">  Hospital Unique Code </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="group_reference_code" id="group_reference_code"
                                [ngClass]="{ 'is-invalid': submitted && f.group_reference_code.errors }" maxlength="20" 
                                class="form-control form-control-sm" readonly>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="hide==1"> 
                    <div class="form-group">
                        <label class="control-label"> Individual Unique Code </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="individual_reference_code"
                                [ngClass]="{ 'is-invalid': submitted && f.individual_reference_code.errors }" maxlength="20"
                                class="form-control form-control-sm" id="individual_reference_code" readonly>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="!is1962LiveStock && !EnableHumanMMU">
                    <div class="form-group">
                        <label class="control-label">Exclusivity for Reference Code &nbsp;&nbsp;&nbsp; 
                        <input type="checkbox" formControlName="exclusive_reference_code" id="exclusive_reference_code"
                            [attr.disabled]="isEditable ? true : null"></label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-3" *ngIf="(+TypeOfDoctor.value==5 && isReferralEnable) ">
                    <div class="form-group">
                        <label class="control-label">Category Type <span class="text-danger" *ngIf="+TypeOfDoctor.value==5">*</span> </label>
                        <div>
                          <select class="form-control form-control-sm" id="centerType" formControlName="center_type"
                          [ngClass]="{ 'is-invalid': submitted && f.center_type.errors }" (change)='changeHospitalType($event.target.value)' >
                              <!-- <option value="Center">Center</option> -->
                              <option value="" *ngIf="!isEdit">Select</option>
                              <option *ngFor="let type of getallhospitalTypeList" [value]="type">
                                {{type}}</option>
                          </select>
                          <div *ngIf="submitted && f.center_type.errors" class="invalid-feedback">
                            <div *ngIf="f.center_type.errors.required">Category Type is required</div>
                        </div>
                         
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="isReferralEnable && doctorForm.get('center_type').value == 'Referral'">
                    <div class="form-group">
                        <label class="control-label">Type of Referral Hospitals <span class="text-danger">*</span> </label>
                        <div>
                            <select class="form-control form-control-sm" id="hospital_type_id" 
                            formControlName="hospital_type_id" 
                            [ngClass]="{ 'is-invalid': submitted && f.hospital_type_id.errors }" (change)='changeReferralType($event.target.value)'>
                                <option value="" *ngIf="!isEdit">Select</option>
                                <option *ngFor="let type of getallRefhospitalTypesList" [value]="type.id">
                                  {{type.hospital_type}}</option>
                            </select>
                            <div *ngIf="submitted && f.hospital_type_id.errors" class="invalid-feedback">
                                <div *ngIf="f.hospital_type_id.errors.required">Type of Referral Hospitals is required</div>
                            </div>
                         
                        </div>
                    </div>
                </div>

                <div class="col-xl-3" *ngIf="!isReferralEnable">
                    <div class="form-group">
                        <label class="control-label">Hospital Name<span class="text-danger" *ngIf="+TypeOfDoctor.value==1||+TypeOfDoctor.value==5 ||+TypeOfDoctor.value==6">*</span> </label>
                        <div>
                            <select formControlName="hospital_name" id="hospital_name"
                                [ngClass]="{ 'is-invalid': submitted && f.hospital_name.errors }"
                                [attr.disabled]="((+TypeOfDoctor.value==1 ||+TypeOfDoctor.value==5 ||+TypeOfDoctor.value==6) && !isEditable)?null:true"
                                class="form-control form-control-sm" (change)="onHospitalName($event.target.value)">
                                <option value="">Select</option>
                                <option *ngFor="let hospital of hospitalList" [value]="hospital.id">
                                    {{hospital.hospital_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.hospital_name.errors" class="invalid-feedback">
                                <div *ngIf="f.hospital_name.errors.required">Hospital Name is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="isReferralEnable && doctorForm.get('center_type').value == 'Center'">
                    <div class="form-group">
                        <label class="control-label">Hospital Name<span class="text-danger" *ngIf="+TypeOfDoctor.value==1||+TypeOfDoctor.value==5 ||+TypeOfDoctor.value==6">*</span> </label>
                        <div>
                            <select formControlName="hospital_name" id="hospital_name"
                                [ngClass]="{ 'is-invalid': submitted && f.hospital_name.errors }"
                                [attr.disabled]="((+TypeOfDoctor.value==1 ||+TypeOfDoctor.value==5 ||+TypeOfDoctor.value==6) && !isEditable)?null:true"
                                class="form-control form-control-sm" (change)="onHospitalName($event.target.value)">
                                <option value="">Select</option>
                                <option *ngFor="let hospital of hospitalList" [value]="hospital.id">
                                    {{hospital.hospital_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.hospital_name.errors" class="invalid-feedback">
                                <div *ngIf="f.hospital_name.errors.required">Hospital Name is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            
                <div class="col-xl-3" *ngIf="referralHospitalEnable">
                    <div class="form-group">
                        <label class="control-label">Referral Hospitals <span class="text-danger">*</span></label>
                        <div>                            
                            <select formControlName="hospital_name" id="hospital_name"
                            [ngClass]="{ 'is-invalid': submitted && f.hospital_name.errors }"
                            [attr.disabled]="((+TypeOfDoctor.value==1 ||+TypeOfDoctor.value==5 ||+TypeOfDoctor.value==6) && !isEditable)?null:true"
                            class="form-control form-control-sm" (change)="onHospitalName($event.target.value)">
                            <option value="">Select</option>
                                    <option *ngFor="let ref of hospitalList" [value]="ref.id">{{ref.hospital_name}}</option>
                                </select>
                                <div *ngIf="submitted && f.hospital_name.errors" class="invalid-feedback">
                                    <div *ngIf="f.hospital_name.errors.required">Referral Hospitals is required</div>
                                </div>

                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Name <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="first_name" id="first_name"
                                maxlength="20" [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }" 
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'
                                class="form-control form-control-sm text-uppercase"
                                [attr.disabled]="isEditable ? true : null">
                            <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                                <div *ngIf="f.first_name.errors.required">Name is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Surname <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="last_name" id="last_name"
                                [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }" maxlength="20" (focus)="dp.hide()"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'
                                class="form-control form-control-sm text-uppercase"
                                [attr.disabled]="isEditable ? true : null">
                            <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                                <div *ngIf="f.last_name.errors.required">Name is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Employee ID </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="employee_id"
                                class="form-control form-control-sm text-uppercase" id="employee_id"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                (event.charCode >= 97 && event.charCode <= 122)) || (event.charCode >= 48 &&
                                event.charCode <= 57)' [attr.disabled]="isEditable ? true : null" maxlength="20">
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Date of Birth <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="birth_date" id="birth_date"
                                bsDatepicker  #dp="bsDatepicker" class="form-control form-control-sm" [maxDate]="max_date"
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false,adaptivePosition: true,isAnimated: true }"
                                [ngClass]="{ 'is-invalid': submitted && f.birth_date.errors }" (focus)="dp.show()"
                                [attr.disabled]="isEditable ? true : null">
                            <div *ngIf="submitted && f.birth_date.errors" class="invalid-feedback">
                                <div *ngIf="f.birth_date.errors.required">
                                    Date of Birth is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <!-- </div>
            <div class="row"> -->
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Gender <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="gender_id" id="gender_id" (focus)="dp.hide()"
                                [ngClass]="{ 'is-invalid': submitted && f.gender_id.errors }"
                                class="form-control form-control-sm" [attr.disabled]="isEditable ? true : null">
                                <option value="">Select</option>
                                <option *ngFor="let gender of genderList" [value]="gender.id">{{gender.gender_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.gender_id.errors" class="invalid-feedback">
                                <div *ngIf="f.gender_id.errors.required">Gender is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Mobile Number <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="mobile" id="mobile"
                                [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" [pattern]="mobPtrn"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                class="form-control form-control-sm" [maxlength]="maxLngth"
                                (keyup)="onKeyNmbr('mobile', $event.target.value)" [attr.disabled]="isEdit==true?true:null">
                            <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                <div *ngIf="f.mobile.errors?.required">Mobile Number is required</div>
                                <div *ngIf="f.mobile.errors.pattern">Mobile Number {{mobErrMsg}}</div>
                                <!-- <div *ngIf="f.mobile.errors.required">Mobile Number {{mobErrMsg}}</div>
                                <div *ngIf="f.mobile.errors.minLength">Mobile is 10 digits</div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Email <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="email" id="email"
                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" maxlength="60"
                                class="form-control form-control-sm" [attr.disabled]="isEdit==true?true:null"  
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode == 64) ||(event.charCode == 46) ||
                                (event.charCode >= 97 && event.charCode <= 122)) || (event.charCode >= 48 && event.charCode <= 57)'>
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                                <div *ngIf="f.email.errors.pattern">Enter Valid Email</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label" *ngIf="!is1962LiveStock">{{isSAGE ? 'KMPDU' : 'MCI'}} Number <span class="text-danger">*</span></label>
                        <label class="control-label" *ngIf="is1962LiveStock">VCI Number <span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="mci_number"
                                [ngClass]="{ 'is-invalid': submitted && f.mci_number.errors }"
                                class="form-control form-control-sm" maxlength="20"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32)) ||
                                (event.charCode >= 48 && event.charCode <= 57)' id="mci_number"
                                [attr.disabled]="isEditable ? true : null">
                            <div *ngIf="submitted && f.mci_number.errors" class="invalid-feedback">
                                <div *ngIf="!is1962LiveStock &&f.mci_number.errors.required">{{isSAGE ? 'KMPDU' : 'MCI'}} Number is required</div>
                                <div *ngIf=" is1962LiveStock &&f.mci_number.errors.required">VCI Number is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            <!-- </div>
            <div class="row"> -->
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">{{isNIG ? 'States' : 'State'}} <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="state_id" id="state_id" #State
                                (change)="locationChanged(State.value,'district')"
                                [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }"
                                class="form-control form-control-sm"
                                [attr.disabled]="isEditable ? true : null">
                                <option value="">Select</option>
                                <option *ngFor="let state of stateList" [value]="state.id">
                                    {{state.state_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.state_id.errors" class="invalid-feedback">
                                <div *ngIf="f.state_id.errors.required">
                                    {{isNIG ? 'States' : 'State'}} is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Location Type <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="location_type_id" id="location_type_id" #locationId
                                [ngClass]="{ 'is-invalid': submitted && f.location_type_id.errors }"
                                (change)="locationChanged('','location')" class="form-control form-control-sm"
                                [attr.disabled]="isEditable ? true : null">
                                <option value="">Select</option>
                                <option *ngFor="let location of locationTypeList" [value]="location.id">
                                    {{location.location_type}}</option>
                            </select>
                            <div *ngIf="submitted && f.location_type_id.errors" class="invalid-feedback">
                                <div *ngIf="f.location_type_id.errors.required">Location Type is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">{{isNIG ? 'State Capitals' : 'District'}} <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="district_id" id="district_id" #District
                                (change)="locationChanged(District.value,(+locationId.value==2)?'city':'mandal')"
                                [ngClass]="{ 'is-invalid': submitted && f.district_id.errors }"
                                class="form-control form-control-sm" [attr.disabled]="isEditable ? true : null">
                                <option value="">Select</option>
                                <option *ngFor="let district of districtList" [value]="district.id">
                                    {{district.district_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.district_id.errors" class="invalid-feedback">
                                <div *ngIf="f.district_id.errors.required">
                                    {{isNIG ? 'State Capitals' : 'District'}} is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">
                            {{isSAGE ? 'Location' : (isNIG ? 'Local Government Areas' : 'Mandal')}}
                            <span *ngIf="+locationId.value==1" class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="mandal_id" id="mandal_id" #Mandal
                                [attr.disabled]="(+locationId.value==2 || isEditable)?true:null"
                                (change)="locationChanged(Mandal.value,'village')"
                                [ngClass]="{ 'is-invalid': submitted && f.mandal_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let mandal of mandalList" [value]="mandal.id">
                                    {{mandal.mandal_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.mandal_id.errors" class="invalid-feedback">
                                <div *ngIf="f.mandal_id.errors.required">
                                    {{isSAGE ? 'Location' : (isNIG ? 'Local Government Areas' : 'Mandal')}} is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <!-- </div>
            <div class="row"> -->
                <div class="col-xl-3" *ngIf="+locationId.value==2">
                    <div class="form-group">
                        <label class="control-label">City </label>
                        <div>
                            <select formControlName="city_id" id="city_id"
                                [ngClass]="{ 'is-invalid': submitted && f.city_id.errors }"
                                class="form-control form-control-sm"
                                [attr.disabled]="isEditable ? true : null">
                                <option value="">Select</option>
                                <option *ngFor="let city of cityList" [value]="city.id">
                                    {{city.city_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.city_id.errors" class="invalid-feedback">
                                <div *ngIf="f.city_id.errors.required">City is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="+locationId.value != 2 && !isNIG">
                    <div class="form-group">
                        <label class="control-label">Village <span *ngIf="+locationId.value==1" class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="village_id" id="village_id"
                                [attr.disabled]="(+locationId.value==2 || isEditable)?true:null"
                                [ngClass]="{ 'is-invalid': submitted && f.village_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let village of villageList" [value]="village.id">
                                    {{village.village_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.village_id.errors" class="invalid-feedback">
                                <div *ngIf="f.village_id.errors.required">Village is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Department<span class="text-danger" *ngIf="+TypeOfDoctor.value==1||+TypeOfDoctor.value==5 ||+TypeOfDoctor.value==6">*</span> </label>
                        <div>
                            <select formControlName="department_id" id="department_id"
                                [attr.disabled]="((+TypeOfDoctor.value==1||+TypeOfDoctor.value==5 ||+TypeOfDoctor.value==6) && !isEditable)?null:true"
                                [ngClass]="{ 'is-invalid': submitted && f.department_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let dept of departmentList" [value]="dept.department_id">
                                    {{dept.department}}</option>
                            </select>
                            <div *ngIf="submitted && f.department_id.errors" class="invalid-feedback">
                                <div *ngIf="f.department_id.errors.required">Department is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Pin Code <span class="text-danger">*</span>
                        </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="pin_code" id="pin_code"
                                (keyup)="keyUp('pin_code')" maxlength="6" [attr.disabled]="isEditable ? true : null"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                [ngClass]="{ 'is-invalid': submitted && f.pin_code.errors }"
                                class="form-control form-control-sm"> <!-- [minlength]="isSAGE?1:6" -->
                            <div *ngIf="submitted && f.pin_code.errors" class="invalid-feedback">
                                <div *ngIf="f.pin_code.errors?.required">Pin Code is required</div>
                                <div *ngIf="f.pin_code.errors.pattern">Enter Valid Pin Code</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Marital Status<span class="text-danger">*</span></label>
                        <div>
                            <select formControlName="marital_status_id" id="marital_status_id"
                                [ngClass]="{ 'is-invalid': submitted && f.marital_status_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let marital of maritalList" [value]="marital.id">{{marital.marital_status}}</option>
                                
                            </select>
                            <div *ngIf="submitted && f.marital_status_id.errors" class="invalid-feedback">
                                <div *ngIf="f.marital_status_id.errors.required">Marital Status is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row"> -->
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Qualification <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="qualification_id" id="qualification_id"
                                (change)="QualificationChanged($event.target.value)"
                                [ngClass]="{ 'is-invalid': submitted && f.qualification_id.errors }"
                                class="form-control form-control-sm" #QualificationId
                                [attr.disabled]="isEditable ? true : null">
                                <option value="">Select</option>
                                <option *ngFor="let qualification of qualificationsList" [value]="qualification.id">
                                    {{qualification.qualification}}</option>
                            </select>
                            <div *ngIf="submitted && f.qualification_id.errors" class="invalid-feedback">
                                <div *ngIf="f.qualification_id.errors.required">Qualification is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="!hideSpecialization">
                    <div class="form-group">
                        <label class="control-label"> Specialization <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="specialization_id" id="specialization_id"
                                [ngClass]="{ 'is-invalid': submitted && f.specialization_id.errors }"
                                class="form-control form-control-sm" [attr.disabled]="isEditable ? true : null">
                                <option value="">Select</option>
                                <option *ngFor="let specialization of newSpecializationList" [value]="specialization.id">
                                    {{specialization.specialization}}</option>
                            </select>
                            <div *ngIf="submitted && f.specialization_id.errors" class="invalid-feedback">
                                <div *ngIf="f.specialization_id.errors.required">Specialization is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Super Speciality Degree</label>
                        <div>
                            <select formControlName="super_qualification_id" id="super_qualification_id"
                                (change)="superSecialityDegree($event.target.value)"
                                class="form-control form-control-sm" [attr.disabled]="isEditable ? true : null">
                                <option value="">Select</option>
                                <option *ngFor="let superSpecialityDegree of superSpecialityDegreeList"
                                    [value]="superSpecialityDegree.id">{{superSpecialityDegree.qualification}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Super Specialisation</label>
                        <div>
                            <select formControlName="super_specialization_id" id="super_specialization_id"
                                class="form-control form-control-sm" [attr.disabled]="isEditable ? true : null">
                                <option value="">Select</option>
                                <option *ngFor="let super of super_SpecialisationList" [value]="super.id">
                                    {{super.specialization}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Other Qualifications, if any</label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="other_qualification" id="other_qualification"
                                [ngClass]="{ 'is-invalid': submitted && f.other_qualification.errors }" maxlength="100"
                                placeholder="Other Qualifications, if any" class="form-control form-control-sm"
                                [attr.disabled]="isEditable ? true : null">
                            <div *ngIf="submitted && f.other_qualification.errors" class="invalid-feedback">
                                <div *ngIf="f.other_qualification.errors.maxlength">Should allow up to 100 characters only</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Hospital Working in Presently
                            <span *ngIf="+TypeOfDoctor.value==2 || +TypeOfDoctor.value==3 || +TypeOfDoctor.value ==7" class="text-danger">*</span></label>
                        <div>
                            <select formControlName="hospital_working_in_presently" id="hospital_working_in_presently"
                                class="form-control form-control-sm" [attr.disabled]="((+TypeOfDoctor.value==1||+TypeOfDoctor.value==5 ||+TypeOfDoctor.value==6) || isEditable)?true:null"
                                [ngClass]="{ 'is-invalid': submitted && f.hospital_working_in_presently.errors }"
                                (change)="OtherHsptl.value='';onHospitalWorking('dd', $event.target.value)" #HospitalPresent>
                                <option value="">Select</option>
                                <option *ngFor="let hospital of hospitalList" [value]="hospital.id">
                                    {{hospital.hospital_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.hospital_working_in_presently.errors" class="invalid-feedback">
                                <div *ngIf="f.hospital_working_in_presently.errors.required">Hospital Working in Presently is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Other Hospital</label>
                        <div>
                            <!-- [attr.disabled]="(+TypeOfDoctor.value==1&&HospitalPresent.value!='')?true:null" -->
                            <input type="text" autocomplete="off" formControlName="other_hospital" id="other_hospital"
                                class="form-control form-control-sm" maxlength="100" #OtherHsptl [readonly]="isEditable"
                                [attr.disabled]="((+TypeOfDoctor.value==1||+TypeOfDoctor.value==5 ||+TypeOfDoctor.value==6) || HospitalPresent.value!='')?true:null"
                                (change)="onHospitalWorking('other',$event.target.value)"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Experience (Years) <span class="text-danger">*</span>
                        </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="years_experience"
                                id="years_experience" maxlength="30" [attr.disabled]="isEditable ? true : null"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                [ngClass]="{ 'is-invalid': submitted && f.years_experience.errors }"
                                class="form-control form-control-sm">
                            <div *ngIf="submitted && f.years_experience.errors" class="invalid-feedback">
                                <div *ngIf="f.years_experience.errors.required">Experience is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="EnableLinkHPR">
                    <div class="form-group">
                        <label class="control-label">HPR ID </label>
                        <div>
                            <input type="text" autocomplete="off" [(ngModel)]="hprId" [ngModelOptions]="{standalone: true}"
                             [attr.disabled]="true" class="form-control form-control-sm">
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="EnableLinkHPR">
                    <div class="form-group">
                        <label class="control-label">HPR Number </label>
                        <div>
                            <input type="text" autocomplete="off" [(ngModel)]="hprNumber" [ngModelOptions]="{standalone: true}"
                                [attr.disabled]="true" class="form-control form-control-sm">
                        </div>
                    </div>
                </div>
              <!-- facility Added start -->
              <!-- <div class="col-xl-3" *ngIf="IsFacilityEnable">
                <label class="control-label"> Select Facility</label>
                <div>
                     <ng-multiselect-dropdown [settings]="dropdownSettings_facility" [placeholder]="'Select'" 
                     [data]="facilityDatalist"
                     [(ngModel)]="selectFacility"  (onSelect)="isFacilitySelect($event)"
                     (onDeSelect)="isFacilityDeSelect($event)"></ng-multiselect-dropdown>
                </div>
            </div> -->
            <div class="col-xl-3" *ngIf="IsFacilityEnable">
              <div class="form-group">
                <label class="control-label"> Facility ID <span class="text-danger">*</span> </label>
                <div>
                  <input type="text" autocomplete="off" formControlName="facility_id" id="facility_id" [attr.disabled]="true"
                     maxlength="50" 
                    class="form-control form-control-sm" >
                </div>
              </div>
            </div>
            <!-- [attr.disabled]="facilitySelected?true:null" -->
            <div class="col-xl-3" *ngIf="IsFacilityEnable">
              <div class="form-group">
                <label class="control-label"> Facility Name <span class="text-danger">*</span> </label>
                <div>
                  <input type="text" autocomplete="off" formControlName="facility_name" id="facility_name" [attr.disabled]="true"
                     maxlength="50"
                    class="form-control form-control-sm">
                </div>
              </div>
            </div>

          <!-- facility added End -->

                <div class="col-xl-3" *ngIf="!IsPaymentdisable">
                    <div class="form-group">
                        <label class="control-label">Audio Consultation Rate<span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="audio_consultation_price"
                                id="audio_consultation_price" maxlength="5"
                                [ngClass]="{ 'is-invalid': submitted && f.audio_consultation_price.errors }"
                                onkeypress='return  (event.charCode >= 48 && event.charCode <= 57)'
                                class="form-control form-control-sm">
                            <div *ngIf="submitted && f.audio_consultation_price.errors" class="invalid-feedback">
                                <div *ngIf="f.audio_consultation_price.errors.required">Audio Consultation Rate is
                                    required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="!IsPaymentdisable">
                    <div class="form-group">
                        <label class="control-label">Video Consultation Rate<span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="video_consultation_price"
                                id="video_consultation_price" maxlength="5" class="form-control form-control-sm"
                                [ngClass]="{ 'is-invalid': submitted && f.video_consultation_price.errors }"
                                onkeypress='return  (event.charCode >= 48 && event.charCode <= 57)' (focus)="dp1.hide()">
                            <div *ngIf="submitted && f.video_consultation_price.errors" class="invalid-feedback">
                                <div *ngIf="f.video_consultation_price.errors.required">
                                    Video Consultation Rate is required</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-3" [ngClass]="showCharge?'':'d-none'" *ngIf="!is1962LiveStock && !EnableHumanMMU">
                    <div class="form-group">
                        <label class="control-label"> is Health Worker</label>
                        <div>
                            <input type="checkbox" formControlName="isHW" (change)="isHealth($event.target.checked)">
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" [ngClass]="showCharge?'':'d-none'" *ngIf="!is1962LiveStock && !EnableHumanMMU">
                    <div class="form-group">
                        <label class="control-label"> Health Worker</label>
                        <div>
                            <!-- [attr.disabled]="isHW.checked?null:true" -->
                            <select formControlName="hlthWorker" id="hlthWorker"
                            [attr.disabled]="isHW?null:true" class="form-control form-control-sm" #HW >
                                <option value="">Select</option>
                                <option *ngFor="let hw of hwList" [value]="hw.id">{{hw.first_name+' '+hw.last_name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            
                <div class="col-xl-3" *ngIf="!IsPaymentdisable">
                    <div class="form-group" *ngIf="showCharge">
                        <label class="control-label"> Is Doctor Chargeable</label>
                        <div>
                            <input type="checkbox" formControlName="charge_amt">
                        </div>
                    </div>
                </div>

                <!-- </div><div class="row"> -->
                <!-- <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Available on Days <span class="text-danger">*</span>
                        </label>
                        <div>
                            <ng-multiselect-dropdown [settings]="dropdownSettings_weekDays" [placeholder]="'All'"
                                [data]="weekDaysList" formControlName="weekday_id"></ng-multiselect-dropdown>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2">
                    <div class="form-group">
                        <label class="control-label">Available From Time<span class="text-danger">*</span></label>
                        <div>
                            <timepicker formControlName="from_time" [showMeridian]="isMeridian"></timepicker>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2">
                    <div class="form-group">
                        <label class="control-label">Available To Time<span class="text-danger">*</span></label>
                        <div>
                            <timepicker formControlName="to_time" [showMeridian]="isMeridian"></timepicker>
                        </div>
                    </div>
                </div> -->
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Registration Expiry Date<span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="reg_expiry_date" id="reg_expiry_date" 
                                bsDatepicker #dp1="bsDatepicker" [minDate]="maxdate" class="form-control form-control-sm"
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false,adaptivePosition: true,isAnimated: true }"
                                [ngClass]="{ 'is-invalid': submitted && f.reg_expiry_date.errors }" (focus)="dp1.show()"
                                [attr.disabled]="isEditable ? true : null">
                            <div *ngIf="submitted && f.reg_expiry_date.errors" class="invalid-feedback">
                                <div *ngIf="f.reg_expiry_date.errors.required">Registration Expiry Date is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- </div><div class="row"> -->
                <div class="col-xl-3" *ngIf="!is1962LiveStock && !EnableHumanMMU">
                    <div class="form-group">
                        <label class="control-label">Maximum Consultation Time</label>
                        <div>
                            <!-- <input type="text" autocomplete="off" formControlName="max_consultation_time"
                                id="max_consultation_time" placeholder="Maximum Consultation Time"
                                class="form-control form-control-sm"> -->
                            <select formControlName="max_consultation_time" id="max_consultation_time" (focus)="dp1.hide()"
                                class="form-control form-control-sm" [attr.disabled]="isEditable ? true : null"
                                [ngClass]="{ 'is-invalid': submitted && f.max_consultation_time.errors }">
                                <option value="">Select</option>
                                <option value="10">10 Minutes</option>
                                <option value="15">15 Minutes</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Languages Preferred <span class="text-danger">*</span> </label>
                        <div>                            
                            <div tabindex="0" class="" >
                                <ng-multiselect-dropdown [settings]="dropdownSettings_language" [placeholder]="'All'"
                                    [data]="languagesList" [ngClass]="{ 'is-invalid': submitted && f.doctor_languages.errors }"
                                    [disabled]="isEditable" formControlName="doctor_languages"></ng-multiselect-dropdown>
                                <div *ngIf="submitted && f.doctor_languages.errors" class="invalid-feedback" >
                                    <div *ngIf="f.doctor_languages.errors.required">Languages is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Status <span class="text-danger">*</span>
                        </label>
                        <div>
                            <input type="radio" formControlName="account_activated" [attr.disabled]="isEditable?true:null" [value]="true">Active
                            <input type="radio" formControlName="account_activated" [attr.disabled]="isEditable?true:null" [value]="false">In Active
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="!is1962LiveStock && !EnableHumanMMU">
                    <div class="form-group">
                        <label class="control-label">Send Notifications to Mobile</label>
                        <div>
                            <input type="checkbox" formControlName="send_notification_to_mobile" [attr.disabled]="isEditable?true:null">
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="+doctorForm.get('doctor_type_id').value==2">
                    <div class="form-group">
                        <label class="control-label">Bank Account Number <span class="text-danger">*</span> </label>
                        <div>

                            <input type="text" autocomplete="off" formControlName="bank_account_number"
                                id="bank_account_number" class="form-control form-control-sm" maxlength="20"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                (keyup)="keyUp('bank_account_number')" [attr.disabled]="isEditable ? true : null"
                                [ngClass]="{ 'is-invalid': submitted && doctorForm.get('bank_account_number').invalid }">
                            <div *ngIf="submitted && doctorForm.get('bank_account_number').invalid"
                                class="invalid-feedback">
                                <div *ngIf="doctorForm.get('bank_account_number').errors.required">
                                    Bank Account Number is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="+doctorForm.get('doctor_type_id').value==2">
                    <div class="form-group">
                        <label class="control-label"> Bank Name <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="branch_name" id="branch_name" class="form-control form-control-sm"
                                [ngClass]="{ 'is-invalid': submitted && doctorForm.get('branch_name').errors }"
                                [attr.disabled]="isEditable ? true : null">
                                <option value="">Select</option>
                                <option *ngFor="let item of bankNamesList" [value]="item.id">
                                    {{item.bank_name}}</option>
                            </select>
                            <div *ngIf="submitted && doctorForm.get('branch_name').invalid" class="invalid-feedback">
                                <div *ngIf="doctorForm.get('branch_name').errors.required">
                                    Bank Name is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="+doctorForm.get('doctor_type_id').value==2">
                    <div class="form-group">
                        <label class="control-label"> Branch Location <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="branch_location" id="branch_location"
                                class="form-control form-control-sm" [attr.disabled]="isEditable ? true : null"
                                [ngClass]="{ 'is-invalid': submitted && doctorForm.get('branch_location').invalid }"
                                (keyup)="keyUp('branch_location')" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32))' maxlength="50">
                            <div *ngIf="submitted && doctorForm.get('branch_location').invalid"
                                class="invalid-feedback">
                                <div *ngIf="doctorForm.get('branch_location').errors.required">
                                    Branch Location is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="+doctorForm.get('doctor_type_id').value==2 && !isNIG">
                    <div class="form-group">
                        <label class="control-label">{{isSAGE ? 'SWIFT' : 'IFSC'}} Code<span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="ifsc_code" id="ifsc_code"
                                class="form-control form-control-sm" (keyup)="keyUp('ifsc_code')"
                                [ngClass]="{ 'is-invalid': submitted && doctorForm.get('ifsc_code').invalid }"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32)) ||
                                    (event.charCode >= 48 && event.charCode <= 57)'
                                [attr.disabled]="isEditable ? true : null" maxlength="11">
                            <div *ngIf="submitted && doctorForm.get('ifsc_code').invalid" class="invalid-feedback">
                                <div *ngIf="doctorForm.get('ifsc_code').errors.required">
                                    {{isSAGE ? 'SWIFT' : 'IFSC'}} Code is required</div>
                                <div *ngIf="doctorForm.get('ifsc_code').errors.pattern">
                                    Enter Valid {{isSAGE ? 'SWIFT' : 'IFSC'}} Code, ex: SBIN0001050</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- </div><div class="row"> -->
                <!-- <div class="col-xl-3" *ngIf="!isNIG">
                    <div class="form-group">
                        <label class="control-label">{{isSAGE ? 'National ID' : 'Aadhaar Number'}}</label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="aadhar_number" id="aadhar_number"
                                (keyup)="keyUp('aadhar_number')" class="form-control form-control-sm" maxlength="12"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                [attr.disabled]="isEditable ? true : null"
                                [ngClass]="{ 'is-invalid': submitted && doctorForm.get('aadhar_number').invalid }">
                            <div *ngIf="submitted && doctorForm.get('aadhar_number').invalid" class="invalid-feedback">
                                <div *ngIf="doctorForm.get('aadhar_number').errors.pattern">
                                    Enter Valid {{isSAGE ? 'National ID' : 'Aadhaar Number'}} </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="col-xl-3" *ngIf="!is1962LiveStock && !EnableHumanMMU">
                    <div class="form-group">
                        <label class="control-label">Home Address </label>
                        <div>
                            <!-- [attr.disabled]="(+TypeOfDoctor.value==2)?null:true" (change)="onAdrsChng('ha', $event.target.value)" -->
                            <textarea rows="2" class="form-control form-control-sm" [readonly]="isEditable"
                                id="home_address" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32) || (event.charCode == 44)|| 
                                (event.charCode == 45)|| (event.charCode == 47)) ||  (event.charCode >= 48 && event.charCode <= 57)'
                                maxlength="100" (keyup)="keyUp('home_address')" formControlName="home_address"
                                [attr.disabled]="(+TypeOfDoctor.value==2)?null:true"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="!is1962LiveStock && !EnableHumanMMU">
                    <div class="form-group">
                        <label class="control-label">Clinic Address </label>
                        <div>
                            <!-- [attr.disabled]="(+TypeOfDoctor.value>=2)?null:true" (change)="onAdrsChng('ca', $event.target.value)" -->
                            <textarea class="form-control form-control-sm" formControlName="clinic_address" rows="2"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32) || (event.charCode == 44)|| 
                                (event.charCode == 45)|| (event.charCode == 47)) ||  (event.charCode >= 48 && event.charCode <= 57)'
                                maxlength="100" (keyup)="keyUp('clinic_address')" [readonly]="isEditable" id="clinic_address"
                                [attr.disabled]="(+TypeOfDoctor.value==1||+TypeOfDoctor.value==5 ||+TypeOfDoctor.value==6)?true:null"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Hospital Address </label>
                        <div>
                            <!-- (change)="onAdrsChng('la', $event.target.value)" -->
                            <textarea rows="2" class="form-control form-control-sm" formControlName="hospital_address"
                                id="hospital_address" [readonly]="isEditable" [attr.disabled]="(+TypeOfDoctor.value==3)?true:null"
                                maxlength="100" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32) || (event.charCode == 44)|| 
                                (event.charCode == 45)|| (event.charCode == 47)) ||  (event.charCode >= 48 && event.charCode <= 57)'></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 mb-2" *ngIf="is1962LiveStock || EnableHumanMMU">
                    <label class="control-label">Work Shift <span class="text-danger">*</span></label>
                    <div>
                      <select id="work_shift_id" class="form-control form-control-sm" formControlName="work_shift_id"
                      [ngClass]="{ 'is-invalid': submitted && doctorForm.get('work_shift_id').invalid }">
                        <option value="">Select</option>
                        <option *ngFor="let item of wrkShiftLst" [value]="item.id">
                         {{item?.work_shift_name}} ({{item?.work_shift_start_time}} - {{item?.work_shift_end_time}})</option>
                      </select>
                      <div *ngIf="submitted && doctorForm.get('work_shift_id').invalid" class="invalid-feedback">
                      <div *ngIf="doctorForm.get('work_shift_id').errors.required">
                        Work Shift is required</div>
                  </div>
                    </div>
                  
                  </div>
                <!-- </div><div class="row"> -->
                <div class="col-xl-3" *ngIf="!is1962LiveStock && !EnableHumanMMU">
                    <div class="form-group">
                        <label class="control-label">Address for Prescription</label>
                        <div>
                            <select formControlName="prescription_address" id="prescription_address"
                                [attr.disabled]="(+TypeOfDoctor.value==2 && !isEditable)?null:true"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option value="1">Home</option>
                                <option value="2">Clinic</option>
                                <option value="3">Hospital</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="!is1962LiveStock && !EnableHumanMMU">
                    <div class="form-group">
                        <label class="control-label">Pharmacy Preferred</label>
                        <div>
                            <select formControlName="pharmacy_id" id="pharmacy_id" class="form-control form-control-sm"
                                [attr.disabled]="(+TypeOfDoctor.value==2 && !isEditable)?null:true">
                                <option value="">Select</option>
                                <option *ngFor="let pharmacy of pharmacyList" [value]="pharmacy.id">
                                    {{pharmacy.pharmacy_name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="!is1962LiveStock && !EnableHumanMMU">
                    <div class="form-group">
                        <label class="control-label"> Lab Preferred</label>
                        <div>
                            <select formControlName="lab_id" id="lab_id" class="form-control form-control-sm"
                                [attr.disabled]="(+TypeOfDoctor.value==2 && !isEditable)?null:true">
                                <option value="">Select</option>
                                <option *ngFor="let lab of labList" [value]="lab.id">{{lab.lab_name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="!is1962LiveStock && !EnableHumanMMU">
                    <div class="form-group">
                        <label class="control-label"> Assistant Details</label>
                        <div>
                            <input type="checkbox" formControlName="assistant_detail" (change)="assistantDetails($event.target.checked)"
                                [attr.disabled]="isEditable ? true : null">
                        </div>
                    </div>
                </div>
                <!-- <div class="col-xl-3"></div> -->
                <!-- </div><div class="row"> -->
                <div class="col-xl-3" *ngIf="!is1962LiveStock && !EnableHumanMMU">
                    <div class="form-group">
                        <label class="control-label">Assistant Name <span *ngIf="AssistantDetails" class="text-danger">*</span></label>
                        <div>
                            <input type="text" [attr.disabled]="AssistantDetails?null:true" autocomplete="off"
                                formControlName="assistant_first_name" id="assistant_first_name" [readonly]="isEditable"
                                [ngClass]="{'is-invalid': submitted && doctorForm.get('assistant_first_name').invalid }"
                                class="form-control form-control-sm text-uppercase" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32))'
                                maxlength="50" (keyup)="keyUp('assistant_first_name')">
                            <div *ngIf="submitted && doctorForm.get('assistant_first_name').errors"
                                class="invalid-feedback">
                                <div *ngIf="doctorForm.get('assistant_first_name').errors.required">Assistant Name is
                                    required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="!is1962LiveStock && !EnableHumanMMU">
                    <div class="form-group">
                        <label class="control-label">Contact Number <span *ngIf="AssistantDetails" class="text-danger">*</span></label>
                        <div>
                            <input type="text" [attr.disabled]="AssistantDetails?null:true" autocomplete="off"
                                maxlength="12" formControlName="assistant_contact_number" id="assistant_contact_number"
                                [ngClass]="{'is-invalid': submitted && doctorForm.get('assistant_contact_number').invalid }"
                                class="form-control form-control-sm" [pattern]="mobPtrn" [maxlength]="maxLngth"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)' [readonly]="isEditable"
                                (keyup)="onKeyNmbr('assistant_contact_number', $event.target.value)"> <!-- (keyup)="keyUp('assistant_contact_number')"> -->
                            <div *ngIf="submitted && doctorForm.get('assistant_contact_number').errors" 
                                class="invalid-feedback">
                                <div *ngIf="doctorForm.get('assistant_contact_number').errors.required">Contact
                                    Number is required</div>                                
                                <div *ngIf="f.assistant_contact_number.errors.pattern">Contact Number {{mobErrMsg}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="!is1962LiveStock && !EnableHumanMMU">
                    <div class="form-group">
                        <label class="control-label">Email <span *ngIf="AssistantDetails" class="text-danger">*</span></label>
                        <div>
                            <input type="text" [attr.disabled]="AssistantDetails?null:true" autocomplete="off"
                                formControlName="assistant_email" id="assistant_email" maxlength="60"
                                [ngClass]="{'is-invalid': submitted && doctorForm.get('assistant_email').invalid }"
                                class="form-control form-control-sm" [readonly]="isEditable">
                            <div *ngIf="submitted && doctorForm.get('assistant_email').errors" class="invalid-feedback">
                                <div *ngIf="doctorForm.get('assistant_email').errors.required">Email is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="!is1962LiveStock && !EnableHumanMMU">
                    <div class="form-group">
                        <label class="control-label"> Send Notification</label>
                        <div>
                            <input type="checkbox" [attr.disabled]="AssistantDetails?null:true"
                                formControlName="notification_to_assistant" [readonly]="isEditable">
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="!is1962LiveStock && !EnableHumanMMU">
                    <div class="form-group">
                        <label class="control-label">Doctor Module</label>
                        <div>                            
                            <div tabindex="0" class="">
                                <!-- <ng-multiselect-dropdown [settings]="dropdownSettings_module" [placeholder]="'Select'"
                                    [data]="doctorlist" [disabled]="isEditable" formControlName="doctor_modules"></ng-multiselect-dropdown> -->
                                <select formControlName="docTmplt" id="docTmplt" class="form-control form-control-sm"
                                    (change)="onChngDT($event.target.value)">
                                    <option value="">Select</option>
                                    <option *ngFor="let dt of doctorlist" [value]="dt.id">{{dt.module_name}}</option>
                                </select>
                                <!-- [ngClass]="{ 'is-invalid': submitted && f.doctor_modules.errors }" -->
                                <!-- <div *ngIf="submitted && f.doctor_modules.errors" class="invalid-feedback" >
                                    <div *ngIf="f.doctor_modules.errors.required">Languages is required</div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2" *ngIf="!is1962LiveStock && !EnableHumanMMU">
                    <div class="form-group">
                        <label class="control-label">Doctor Start Hours</label>
                        <div>                            
                            <div tabindex="0" class="">
                                <!-- <ng-multiselect-dropdown [settings]="dropdownSettings_module" [placeholder]="'Select'"
                                    [data]="doctorlist" [disabled]="isEditable" formControlName="doctor_modules"></ng-multiselect-dropdown> -->
                                <select formControlName="start_hours" id="start_hours" class="form-control form-control-sm">
                                    <option value="">Select</option>
                                    <option *ngFor="let hr of lstHrs" [value]="hr.name">{{hr.name}}</option>
                                </select>
                                <!-- [ngClass]="{ 'is-invalid': submitted && f.doctor_modules.errors }" -->
                                <!-- <div *ngIf="submitted && f.doctor_modules.errors" class="invalid-feedback" >
                                    <div *ngIf="f.doctor_modules.errors.required">Languages is required</div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2" *ngIf="!is1962LiveStock && !EnableHumanMMU">
                    <div class="form-group">
                        <label class="control-label">Doctor Start Minutes</label>
                        <div>                            
                            <div tabindex="0" class="">
                                <!-- <ng-multiselect-dropdown [settings]="dropdownSettings_module" [placeholder]="'Select'"
                                    [data]="doctorlist" [disabled]="isEditable" formControlName="doctor_modules"></ng-multiselect-dropdown> -->
                                <select formControlName="start_minutes" id="start_minutes" class="form-control form-control-sm">
                                    <option value="">Select</option>
                                    <option *ngFor="let mt of lstMin" [value]="mt.name">{{mt.name}}</option>
                                </select>
                                <!-- [ngClass]="{ 'is-invalid': submitted && f.doctor_modules.errors }" -->
                                <!-- <div *ngIf="submitted && f.doctor_modules.errors" class="invalid-feedback" >
                                    <div *ngIf="f.doctor_modules.errors.required">Languages is required</div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2" *ngIf="!is1962LiveStock && !EnableHumanMMU">
                    <div class="form-group">
                        <label class="control-label">Doctor End Hours</label>
                        <div>                            
                            <div tabindex="0" class="">
                                <!-- <ng-multiselect-dropdown [settings]="dropdownSettings_module" [placeholder]="'Select'"
                                    [data]="doctorlist" [disabled]="isEditable" formControlName="doctor_modules"></ng-multiselect-dropdown> -->
                                <select formControlName="end_hours" id="end_hours" class="form-control form-control-sm">
                                    <option value="">Select</option>
                                    <option *ngFor="let hr of lstHrs" [value]="hr.name">{{hr.name}}</option>
                                </select>
                                <!-- [ngClass]="{ 'is-invalid': submitted && f.doctor_modules.errors }" -->
                                <!-- <div *ngIf="submitted && f.doctor_modules.errors" class="invalid-feedback" >
                                    <div *ngIf="f.doctor_modules.errors.required">Languages is required</div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2" *ngIf="!is1962LiveStock && !EnableHumanMMU">
                    <div class="form-group">
                        <label class="control-label">Doctor End Minutes</label>
                        <div>                            
                            <div tabindex="0" class="">
                                <!-- <ng-multiselect-dropdown [settings]="dropdownSettings_module" [placeholder]="'Select'"
                                    [data]="doctorlist" [disabled]="isEditable" formControlName="doctor_modules"></ng-multiselect-dropdown> -->
                                <select formControlName="end_minutes" id="end_minutes" class="form-control form-control-sm">
                                    <option value="">Select</option>
                                    <option *ngFor="let mt of lstMin" [value]="mt.name">{{mt.name}}</option>
                                </select>
                                <!-- [ngClass]="{ 'is-invalid': submitted && f.doctor_modules.errors }" -->
                                <!-- <div *ngIf="submitted && f.doctor_modules.errors" class="invalid-feedback" >
                                    <div *ngIf="f.doctor_modules.errors.required">Languages is required</div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12">
                    <div class="table-responsive">
                        <table class="table table-bordered table-sm">
                            <tbody>
                                <tr [hidden]="isEditable">
                                    <td>
                                        <div>
                                            <input type="file" class="d-none" id="digitalCertificate" #digitalCertificate
                                                formControlName="digitalFile" (change)="singleFileUpload($event,1,'8','digitalFile')"
                                                [ngClass]="{'is-invalid': submitted && f.digitalFile.invalid }">
                                                <p class="btn badge badge-success badge-pill text-white"
                                                    (click)="digitalCertificate.click()">Digital Signature</p>
                                                <span class="text-danger">*</span> <p class="small mb-1">Png format only</p>
                                            <div *ngIf="submitted && f.digitalFile.errors" class="invalid-feedback">
                                                <div *ngIf="f.digitalFile.errors.required">Digital Signature is required</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <input type="file" class="d-none" id="mciCertificate" #mciCertificate
                                            formControlName="qualificationFile" (change)="singleFileUpload($event,2,'9','qualificationFile')"
                                            [ngClass]="{'is-invalid': submitted && f.qualificationFile.invalid }">
                                        <!-- <a class="btn btn-sm btn-primary text-white"
                                            (click)="mciCertificate.click()">Highest Qualification Certificate <span
                                                class="text-danger">*</span></a> -->
                                        <p class="btn badge badge-success badge-pill text-white"
                                            (click)="mciCertificate.click()">Highest Qualification Certificate</p>
                                            <span class="text-danger">*</span>
                                        <p class="small mb-1">jpg or pdf format only</p>
                                        <div *ngIf="submitted && f.qualificationFile.errors" class="invalid-feedback">
                                            <div *ngIf="f.qualificationFile.errors.required">Qualification Certificate is
                                                required
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <input type="file" class="d-none" id="bioData" #bioData
                                            (change)="singleFileUpload($event,3,'4')">
                                        <!-- <a class="btn btn-sm btn-primary text-white" (click)="bioData.click()">Bio Data</a> -->
                                        <p class="btn badge badge-success badge-pill text-white" (click)="bioData.click()">
                                            Bio Data</p>
                                        <p class="small mb-1">word or pdf format only</p>
                                    </td>
                                    <td>
                                        <input type="file" class="d-none" id="photo" #photo
                                            formControlName="photo" (change)="singleFileUpload($event,4,'5','photo')"
                                            [ngClass]="{'is-invalid': submitted && f.photo.invalid }">
                                        <!-- <a class="btn btn-sm btn-primary text-white" (click)="photo.click()">Photo </a> -->
                                        <p class="btn badge badge-success badge-pill text-white"
                                            (click)="photo.click()">Photo</p>   <span class="text-danger" *ngIf="!is1962LiveStock && !EnableHumanMMU">*</span>
                                        <p class="small mb-1">jpg,bmp format only</p>
                                        <div *ngIf="submitted && f.photo.errors" class="invalid-feedback">
                                            <div *ngIf="f.photo.errors.required">Photo is required
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <input type="file" class="d-none" id="audioRecord" #audioRecord
                                            (change)="singleFileUpload($event,5,'10')">
                                        <!-- <a class="btn btn-sm btn-primary text-white" (click)="audioRecord.click()">Video
                                            Record </a> -->
                                        <p class="btn badge badge-success badge-pill text-white"
                                            (click)="audioRecord.click()">Video Record </p>
                                        <p class="small mb-1">mp4 format only</p>
                                    </td>
                                    <!-- <td *ngIf="!isNIG">
                                        <input type="file" class="d-none" id="aadharFile" #aAdhar
                                            formControlName="aadharFile" (change)="singleFileUpload($event,6,'12')"
                                            [ngClass]="{'is-invalid': submitted && f.aadharFile.invalid }">
                                        <p class="btn badge badge-success badge-pill text-white" (click)="aAdhar.click()">
                                            {{isSAGE ? 'National ID' : 'Aadhaar'}} </p>
                                        <p class="small mb-1">jpg or pdf format only</p>
                                        <div *ngIf="submitted && f.aadharFile.errors" class="invalid-feedback">
                                            <div *ngIf="f.aadharFile.errors.required">
                                                {{isSAGE ? 'National ID' : 'Aadhaar'}} is required</div>
                                        </div>
                                    </td> -->
                                    <td>
                                        <input type="file" class="d-none" id="panFile" #PanCard
                                            formControlName="panFile" (change)="singleFileUpload($event,7,'13')"
                                            [ngClass]="{'is-invalid': submitted && f.panFile.invalid }">
                                        <!-- <a class="btn btn-sm btn-primary text-white" (click)="PanCard.click()">Pan Card<span
                                                class="text-danger">*</span></a> -->
                                        <p class="btn badge badge-success badge-pill text-white" (click)="PanCard.click()">
                                            PAN Number </p>
                                        <p class="small mb-1">jpg or pdf format only</p>
                                        <div *ngIf="submitted && f.panFile.errors" class="invalid-feedback">
                                            <div *ngIf="f.panFile.errors.required">PAN Number image is required
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr [hidden]="!isEditable">
                                    <td>
                                        <p class="btn badge badge-success badge-pill text-white">Digital Signature</p> <span class="text-danger">*</span>
                                    </td>
                                    <td>
                                        <p class="btn badge badge-success badge-pill text-white">Highest Qualification Certificate</p> <span class="text-danger">*</span>
                                    </td>
                                    <td>
                                        <p class="btn badge badge-success badge-pill text-white">Bio Data</p>
                                    </td>
                                    <td>
                                        <p class="btn badge badge-success badge-pill text-white">Photo</p> <span class="text-danger">*</span>
                                    </td>
                                    <td>
                                        <p class="btn badge badge-success badge-pill text-white">Video Record </p>
                                    </td>
                                    <td *ngIf="!isNIG">
                                        <!-- <p class="btn badge badge-success badge-pill text-white">{{isSAGE ? 'National ID' : 'Aadhaar'}} </p> -->
                                    </td>
                                    <td>
                                        <p class="btn badge badge-success badge-pill text-white">PAN Number </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            <a class="badge badge-danger badge-pill pointer" title="Cancel File"
                                                *ngIf="uploadFileList[0].File || isShwDS" [hidden]="isEditable"
                                                (click)="cancelFile(1,'digitalFile',8)">
                                                <i class="fa fa-trash text-white"></i>
                                            </a>&nbsp;
                                        </span>
                                        <a href="javascript:void(0)"
                                            (click)="viewFile(1)">{{uploadFileList[0].file_name}}</a></td>
                                    <td>
                                        <span>
                                            <a class="badge badge-danger badge-pill pointer" title="Cancel File"
                                                *ngIf="uploadFileList[1].File || isShwHQ" [hidden]="isEditable"
                                                (click)="cancelFile(2,'qualificationFile',9)">
                                                <i class="fa fa-trash text-white"></i></a>&nbsp;
                                        </span>
                                        <a href="javascript:void(0)"
                                            (click)="viewFile(2)">{{uploadFileList[1].file_name}}</a></td>
                                    <td>
                                        <span>
                                            <a class="badge badge-danger badge-pill pointer" title="Cancel File"
                                                [attr.disabled]="isEditable ? true : null" [hidden]="isEditable"
                                                *ngIf="uploadFileList[2].File" (click)="cancelFile(3)">
                                                <i class="fa fa-trash text-white"></i></a>&nbsp;
                                        </span>
                                        <a href="javascript:void(0)"
                                            (click)="viewFile(3)">{{uploadFileList[2].file_name}}</a></td>
                                    <td>
                                        <span>
                                            <a class="badge badge-danger badge-pill pointer" (click)="cancelFile(4,'photo', 5)"
                                                *ngIf="uploadFileList[3].File || isShwPic" title="Cancel File" [hidden]="isEditable">
                                                <i class="fa fa-trash text-white"></i></a>&nbsp;
                                        </span>
                                        <a href="javascript:void(0)"
                                            (click)="viewFile(4)">{{uploadFileList[3].file_name}}</a></td>
                                    <td>
                                        <span>
                                            <a class="badge badge-danger badge-pill pointer" title="Cancel File"
                                                *ngIf="uploadFileList[4].File" (click)="cancelFile(5,'audioRecord',10)" [hidden]="isEditable">
                                                <i class="fa fa-trash text-white"></i></a>&nbsp;
                                        </span>
                                        <a href="javascript:void(0)"
                                            (click)="viewFile(5)">{{uploadFileList[4].file_name}}</a></td>
                                    <td>
                                        <!-- <span>
                                            <a class="badge badge-danger badge-pill pointer" title="Cancel File"
                                                *ngIf="uploadFileList[5].File" (click)="cancelFile(6)" [hidden]="isEditable">
                                                <i class="fa fa-trash text-white"></i></a>&nbsp;
                                        </span>
                                        <a href="javascript:void(0)"
                                            (click)="viewFile(6)">{{uploadFileList[5].file_name}}</a></td> -->
                                    <td>
                                        <span>
                                            <a class="badge badge-danger badge-pill pointer" title="Cancel File"
                                                *ngIf="uploadFileList[6].File" (click)="cancelFile(7,'PANCard',13)" [hidden]="isEditable">
                                                <i class="fa fa-trash text-white"></i></a>&nbsp;
                                        </span>
                                        <a href="javascript:void(0)" (click)="viewFile(7)">{{uploadFileList[6].file_name}}</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <input type="submit" id="btnsubmit" style="display: none;">
        </form>
    </div>
</div>
<div class="modal-footer">
    <input type="submit" *ngIf="!isSubmit_Disabled" class="btn btn-sm btn-primary" (click)="submitForm()"
        value="Submit">&nbsp;&nbsp; <!-- [disabled]="isSubmit_Disabled?true:null" -->
    <button type="button" class="btn btn-sm btn-outline-dark"  id="doctype1" (click)="activeModal.close('Close click')">Close</button>
</div>


<div class="modal fade t10" id="termsModal">
    <div class="modal-dialog">
        <div class="modal-content modalpopborder" style="width: 51rem;margin-left: -16rem;">
            <div class="modal-header headerbg modalpopborderhead p-2">
                <h6 class="modal-title text-white">Terms and Conditions</h6>
                <button type="button" id="closeid" class="close text-white" (click)="closeterms()">&times;</button>
            </div>
            <div class="modal-body modalbodybg" style="height: 315px;">
               <p style="padding: 0 10px !important;
               margin: 0 !important;
               text-align: justify !important;">
                &nbsp; &nbsp; I, hereby declare that I am voluntarily sharing my Aadhaar Number / Virtual ID and demographic information issued by UIDAI, with National Health Authority (NHA) for the sole purpose of creation of Healthcare Professional ID. I understand that my Healthcare Professional ID can be used and shared for purposes as may be notified by Ayushman Bharat Digital Mission (ABDM) from time to time including provision of healthcare services. Further, I am aware that my personal identifiable information (Name, Address, Age, Date of Birth, Gender and Photograph) may be made available to the entities working in the National Digital Health Ecosystem (NDHE) which inter alia includes stakeholders and entities such as healthcare professional (e.g. doctors), facilities (e.g. hospitals, laboratories) and data fiduciaries (e.g. health programmes), which are registered with or linked to the Ayushman Bharat Digital Mission (ABDM), and various processes there under. I authorize NHA to use my Aadhaar number / Virtual ID for performing Aadhaar based authentication with UIDAI as per the provisions of the Aadhaar (Targeted Delivery of Financial and other Subsidies, Benefits and Services) Act, 2016 for the aforesaid purpose. I understand that UIDAI will share my e-KYC details, or response of “Yes” with NHA upon successful authentication.
            </p>
        <p>
                I consciously choose to use Aadhaar number / Virtual ID for the purpose of availing benefits across the NDHE. I am aware that my personal identifiable information excluding Aadhaar number / VID number can be used and shared for purposes as mentioned above. I reserve the right to revoke the given consent at any point of time as per provisions of Aadhar Act and Regulations and other laws, rules and regulations.
               </p>
            </div>
        </div>
    </div>
</div>